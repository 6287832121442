import React, { useEffect, useState } from 'react';
import {Row, Col, Button, Card, Form} from 'react-bootstrap';
import { Check } from 'react-feather';

import StageCheckMarks from '../../common/StageCheckMarks'

import { secondaryCardColor, cornerRadius, textColor, cardColor } from '../../common/style';


import {Auth} from 'aws-amplify'
import { activeEndpoint, activeLocation } from '../../common/FauxEnv'

 function CompletedCard({CR, index, refreshList}) {

    const [noteText, setNoteText] = useState(CR.notes ?? '');
    const [feedback, setFeedback] = React.useState('');
    const [formattedParticipants, setFormattedParticipants] = useState('');

    useEffect(() => {
        // console.log('p from cr: ', CR.participants)
        var tmpParts = CR.participants.join(', ');
        setFormattedParticipants(tmpParts);


    }, [])

    async function handleSendForSchedule(uncomplete, note, stage = 3){
        // if(selectedParticipants.length < 1){
        //     setFeedback(`Please select at least one participant`);
        //     return;
        // }
        // else if(selectedUrgency === 'none'){
        //     setFeedback(`Please select an urgency`);
        //     return;
        // }
        // else if(selectedMedium === 'none'){
        //     setFeedback(`Please select a medium`);
        //     return;
        // }
        // else {
        //     setFeedback('Sending...')
        //     updateCR()
        // }

        setFeedback('Sending...')
        updateCR(uncomplete, note, stage)  
    }

    async function updateCR(uncomplete = false, note = false, stage = 3) {


        if(uncomplete == false && note == false){
           console.log('no data')
           return
        }
  
        var _keys = []
        var _values = []
        if(uncomplete == true){
           _keys.push('stage')
           _values.push(stage)
        }
        if(note == true){
           _keys.push('notes')
           _values.push(noteText)
        }
  
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`)
  
        var raw = JSON.stringify({
            "id": CR.id,
            "updateKeys": _keys,
            "updateValues": _values
        });
  
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        };
  
        var fetchCode
        fetch(activeEndpoint + '/Meeting/' + CR.id, requestOptions)
          .then(response => {
             fetchCode = response.status
             response.text()})
          .then(result => {
             if(fetchCode === 200){
                setFeedback('Successfully updated')
                console.log('result: ', result)
                refreshList()
             }
             else{
                console.log('error: ', result, '\nwithCode: ', fetchCode)
                setFeedback('ErrorCode: ' + fetchCode)
             }
          })
          .catch(error => console.log('error', error));
  
    }

  return (
      <div style={{
        marginBottom: 100,
      }}>
                <Card 
                    key={CR.id} 
                    style={{  
                        marginRight: 10, 
                        marginBottom: 50,
                        backgroundColor: secondaryCardColor,
                        borderTopLeftRadius: cornerRadius,
                        borderTopRightRadius: cornerRadius,
                    }}
                    testid={'completedCard' + CR.clientName}

                >
                    <div style={{backgroundColor: cardColor, borderTopLeftRadius: cornerRadius, borderTopRightRadius: cornerRadius,}}>
                        <Row
                            style={{  
                                alignItems:  'center',
                                
                            }}
                        >
                            <Col>
                                <Card.Title>
                                    <h4 style={{color: textColor}} className='scheduleCardh4'>{CR.clientName}</h4>
                                </Card.Title>
                            </Col>
                            <Col 
                            >
                                <Card.Body style={{color: textColor}} >
                                    <h5 className='scheduleCardh5'>Due date: {new Date(CR.dueDate).toString().substr(0,15)}</h5>
                                    <h5 className='scheduleCardh5'>Meeting held: {new Date(CR.meetingDate).toString().substr(0,15)}</h5>
                                </Card.Body>
                            </Col>
                            <Col 
                                xs={2}
                                md={2}
                                lg={2}
                                xl={2}
                                xxl={2}
                            >
                                
                                <StageCheckMarks stage={CR.stage} textColor={textColor}/>

                            </Col>
                        </Row>
                    </div>
                    <Row
                        style={{    }}
                    >
                        <Col>
                            <div className="scheduleCardDetailsContainer">
                                <h4 className="scheduleCardDetailsh4">Participants:</h4>
                                <h5 className="scheduleCardDetailsh5">{formattedParticipants ?? '-not specified-'}</h5>
                                
                                <h4 className="scheduleCardDetailsh4">Medium:</h4>
                                <h5 className="scheduleCardDetailsh5">{CR.medium ?? '-not specified-'}</h5>
                                
                                <h4 className="scheduleCardDetailsh4">Urgency:</h4>
                                <h5 className="scheduleCardDetailsh5">{CR.urgency ?? '-not specified-'}</h5>

                                <h4 className="scheduleCardDetailsh4">Agenda Items:</h4>
                                {CR.agendaItems ? CR.agendaItems
                                    .filter(item => item.substring(item.length - 8) != 'prepared')
                                    .map((item, index) => (
                                    <h5 key={item + index} className="scheduleCardDetailsh5">• {item ?? '-not specified-'}</h5>
                                )) : null}
                                <h4 className="scheduleCardDetailsh4">Documents:</h4>
                                {CR.documents ? CR.documents
                                    .filter(item => item.substring(item.length - 8) != 'prepared')                                    
                                    .map((item, index) => (
                                    <h5 key={item + index} className="scheduleCardDetailsh5">• {item ?? '-not specified-'}</h5>
                                )) : null}

                            </div>
                            <Button variant="outline-secondary" onClick={() => handleSendForSchedule(true,false, 3)}
                                    style={{ width: '13rem', margin: 10, marginBottom: 10, justifySelf: 'center'}}
                                >Send back for Scheduling </Button>
                                <Button variant="outline-secondary" onClick={() => handleSendForSchedule(true,false, 2)}
                                    style={{ width: '13rem', margin: 10, marginBottom: 10, justifySelf: 'center'}}
                                >Send back for Review </Button>
                        </Col>
                        <Col>
                            <div className="scheduleCardDetailsContainer notesContainer">
                            <h4>Notes:</h4>
                            <Form>
                                <Form.Control as="textarea" rows={6}
                                    placeholder="Enter notes here"
                                    value={noteText}
                                    onChange={(e) => setNoteText(e.target.value)}
                                    testid={'notesArea'+CR.clientName}
                                />
                            </Form>
                            <Button variant="primary" onClick={() => handleSendForSchedule(false,true)}
                                    style={{ width: '7rem', marginTop: 10, marginBottom: 10, justifySelf: 'center'}}
                                >Save Notes </Button>
                            </div>
                        </Col>

                        
                    </Row>
                    
                </Card>
        <p className="feedbackText">{feedback}</p>
        </div>
    );
}

export default CompletedCard;