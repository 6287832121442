
import { withAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react';

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import ClientReviewMainScreen from './screens/ClientReviewMainScreen';

import Amplify, { Auth } from 'aws-amplify';
import awsconfig from './aws-exports';
Amplify.configure(awsconfig);


function App() {
  return (
    <ClientReviewMainScreen  />
  );
}

export default App;
