import React, { useEffect, useState } from 'react'
import { Row, Col, Card, Container, Form, Button } from 'react-bootstrap'
import { Auth } from 'aws-amplify'
import ReactLoading from 'react-loading'

import { secondaryCardColor } from '../../common/style'

import CompletedList from './CompletedList'
import { activeEndpoint, activeLocation } from '../../common/FauxEnv'
import RadioForm from '../../common/RadioForm'

import './CompletedScreen.css'

function CompletedScreen() {
  const [loading, setLoading] = useState(true)

  const [CRs, setCRs] = useState([])
  const [feedback, setFeedback] = useState('') //todo: change this to a toast
  //    const [awsUser, setAwsUser] = useState('');
  const [search, setSearch] = useState('')
  const [searchFilteredCRs, setSearchFilteredCRs] = useState([])
  const [lastevaluatedkey, setLastEvaluatedKey] = useState(null)

  useEffect(() => {
    console.log('hey')
    getList()
  }, [])

  // useEffect(() => {
  //    if(search.length > 0) {
  //       setSearchFilteredCRs(CRs.filter(cr => cr.name.toLowerCase().includes(search.toLowerCase())));
  //    }
  // }, [search])

  async function handlePaginate() {
    console.log('paginate')
    getList(lastevaluatedkey)
  }

  async function getList(_lastevaluatedkey = null) {
    var myHeaders = new Headers()
    //myHeaders.append("Content-Type", "application/json");
    myHeaders.append('Authorization', `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`)

    const _body = {
      stage: 100,
      limit: 40,
    }
    if (_lastevaluatedkey) {
      _body.lastevaluatedkey = _lastevaluatedkey
    }

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      mode: 'cors',
      body: JSON.stringify(_body),
    }

    var fetchCode
    fetch(activeEndpoint + '/serverless-stamp-custom2-getMeetingByStage', requestOptions)
      .then((response) => {
        fetchCode = response.status
        return response.text()
      })
      .then((result) => {
        if (fetchCode === 200) {
          const parsed = JSON.parse(result)
          console.log('parsed: ', parsed)

          //filter to only stage 100
          const filtered = parsed.CRs.filter((x) => x.stage == 100)

          setLastEvaluatedKey(parsed.LastEvaluatedKey ?? -1)

          setCRs((CRs) => [...CRs, ...filtered].sort((a, b) => (a.submittedAt > b.submittedAt ? -1 : 1)))
          setLoading(false)
        } else {
          console.log('error: ', result, '\nwithCode: ', fetchCode)
          setFeedback('ErrorCode: ' + fetchCode)
          setLoading(false)
        }
      })
      .catch((error) => console.log('error', error))
  }

  function printDataOnClick(data) {
    console.log('data: ', data)
  }

  return (
    <div className='App-topdiv' style={{ justifyContent: 'flex-start' }}>
      <Container fluid style={{ alignSelf: 'flex-start' }}>
        <Row>
          <Col xs={3}>
            <div
              class='sticky-top'
              style={{
                overflowY: 'auto',
                overflowX: 'hidden',
                maxHeight: '99vh',
              }}
            >
              <Row style={{ padding: 20, paddingBottom: 0 }}>
                <h2 className='Createh2'>Filter</h2>
                <div className='bottomLine'></div>
              </Row>

              {/* <Card onClick={() => {setSearch("")}} style={{marginBottom: 20, marginLeft:'20%', marginRight: '20%' }}>
                     <p style={{fontSize: 14, margin: 5, alignSelf: 'center'}}>Show all</p>
                  </Card> */}

              <Button
                variant={'outline-secondary'}
                style={{ marginBottom: 20, marginLeft: '20%', marginRight: '20%', alignSelf: 'center', textAlign: 'center', borderRadius: 20, width: 150 }}
                onClick={() => {
                  setSearch('')
                }}
              >
                Show all
              </Button>

              <Row style={{ padding: 5 }}>
                <Form>
                  <div>
                    <Form.Group className='mb-3' controlId='formBasicEmail'>
                      <h4>Type name...</h4>
                      <Form.Control type='text' placeholder='[Name]' value={search} testid='search_form' onChange={(e) => setSearch(e.target.value)} />
                    </Form.Group>
                  </div>
                </Form>
              </Row>
            </div>
          </Col>
          <Col>
            <Row>
              <h1 className='Createh1'>Completed Meetings</h1>
              <div className='bottomLine'></div>
              <p className='createFeedback'>{feedback}</p>
            </Row>

            <Row>
              <Col className='CompletedList_Container' style={{ borderRight: '1px solid #e6e6e6' }}>
                <div style={{ textAlign: '-webkit-center' }}>{loading ? <ReactLoading type={'spin'} color={secondaryCardColor} /> : null}</div>

                {!loading ? (
                  <>
                    <Row>
                      <h2 className='Createh2'>Newest to Oldest:</h2>
                    </Row>
                    <Row>
                      <CompletedList searchVal={search} CRs={CRs} refreshList={getList} type='all' _style={{ padding: '1%' }} />
                    </Row>
                    {lastevaluatedkey !== -1 ? (
                      <Row className='justify-content-center'>
                        <Button variant='outline-secondary' onClick={() => handlePaginate()} style={{ width: '10rem', margin: 10, marginBottom: 10 }}>
                          Load More
                        </Button>
                      </Row>
                    ) : null}
                  </>
                ) : null}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default CompletedScreen
