import React, { useEffect, useState } from 'react'
import { Row, Col, Card, Container, Form, Button } from 'react-bootstrap'
import { Auth } from 'aws-amplify'
import ReactLoading from 'react-loading'

import { secondaryCardColor } from '../../common/style'

import ToScheduleList from './ToScheduleList'
import { activeEndpoint, activeLocation } from '../../common/FauxEnv'

import './ScheduleScreen.css'
import RadioForm from '../../common/RadioForm'

function Schedule_clientScreen() {
  const [loading, setLoading] = useState(true)
  const [CRs, setCRs] = useState([])
  const [filteredCRs, setFilteredCRs] = useState(CRs)
  const [feedback, setFeedback] = useState('') //todo: change this to a toast

  //    const [awsUser, setAwsUser] = useState('');

  const [search, setSearch] = useState('')
  const [participantFilter, setParticipantFilter] = useState('')
  const [filterTrigger, setFilterTrigger] = useState(false)

  const [allParticipants, setAllParticipants] = useState([])

  const [URLsearch, setURLSearch] = useState(null)

  const thirtyDaysOut = new Date().getTime() + 86400 * 1000 * 30

  useEffect(() => {
    console.log('hey')
    getList()
  }, [])

  useEffect(() => {
    if (filteredCRs.length > 0) {
      var url_string = window.location.href
      var url = new URL(url_string)
      var localURLsearch = url.searchParams.get('search')
      console.log('URLsearch:', localURLsearch)
      if (localURLsearch) {
        setURLSearch(localURLsearch)
      }
    }
  }, [filteredCRs])

  useEffect(() => {
    if (!search && URLsearch) {
      setSearch(URLsearch)
    }
  }, [URLsearch])

  useEffect(() => {
    console.log('CR.length changed to ', CRs.length)
    setFilteredCRs(CRs)

    //go through each CR and pull the unique participants
    let uniqueParticipants = []
    CRs.forEach((cr) => {
      cr.participants.forEach((p) => {
        if (!uniqueParticipants.includes(p)) {
          uniqueParticipants.push(p)
        }
      })
    })
    setAllParticipants(uniqueParticipants)
  }, [CRs])

  useEffect(() => {
    if (filterTrigger) {
      //it actually doesn't matter what the value is i just want to trigger the useEffect
    }
    var tmpCrs = CRs
    if (search === '' || search === undefined || search === null) {
    } else {
      tmpCrs = tmpCrs.filter((cr) => cr.clientName.toLowerCase().includes(search.toLowerCase()))
    }

    if (participantFilter === '' || participantFilter === undefined || participantFilter === null) {
    } else {
      console.log('even getting here?', tmpCrs)
      tmpCrs = tmpCrs.filter((cr) => cr.participants.includes(participantFilter))
      console.log('even finishing?', tmpCrs)
    }

    if (tmpCrs != filteredCRs) {
      console.log('setfilter')
      setFilteredCRs(tmpCrs)
    }
  }, [search, participantFilter, filterTrigger])

  async function getList() {
    var myHeaders = new Headers()
    //myHeaders.append("Content-Type", "application/json");
    myHeaders.append('Authorization', `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`)

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      mode: 'cors',
      body: JSON.stringify({
        stage: 3,
      }),
    }

    var fetchCode
    fetch(activeEndpoint + '/Meeting/stage', requestOptions)
      .then((response) => {
        fetchCode = response.status
        return response.text()
      })
      .then((result) => {
        if (fetchCode === 200) {
          const parsed = JSON.parse(result)
          console.log('parsed: ', parsed)

          //filter to only stage 3
          const filtered = parsed.CRs.filter((x) => x.stage == 3)
          console.log('CRs from Fetch: ', filtered)

          setCRs(filtered.sort((a, b) => a.dueDate - b.dueDate))
          setLoading(false)
          setFilterTrigger(!filterTrigger)
        } else {
          console.log('error: ', result, '\nwithCode: ', fetchCode)
          setFeedback('ErrorCode: ' + fetchCode)
          setLoading(false)
        }
      })
      .catch((error) => console.log('error', error))
  }

  function printDataOnClick(data) {
    console.log('data: ', data)
  }

  return (
    <div className='App-topdiv' style={{ justifyContent: 'flex-start' }}>
      <Container fluid style={{ alignSelf: 'flex-start' }}>
        <Row>
          <Col xs={3}>
            <div
              class='sticky-top'
              style={{
                overflowY: 'auto',
                overflowX: 'hidden',
                maxHeight: '99vh',
              }}
            >
              <Row style={{ padding: 20, paddingBottom: 0 }}>
                <h2 className='Createh2'>Filter</h2>
                <div className='bottomLine'></div>
              </Row>

              {/* <Card onClick={() => {setSearch("")}} style={{marginBottom: 20, marginLeft:'20%', marginRight: '20%' }}>
                     <p style={{fontSize: 14, margin: 5, alignSelf: 'center'}}>Show all</p>
               </Card> */}

              <Button
                variant={'outline-secondary'}
                style={{ marginBottom: 20, marginLeft: '20%', marginRight: '20%', alignSelf: 'center', textAlign: 'center', borderRadius: 20, width: 150 }}
                onClick={() => {
                  setSearch('')
                  setParticipantFilter('')
                }}
              >
                Show all
              </Button>

              <Row>
                <h4>By Participant</h4>
                {allParticipants.length > 0 ? (
                  <RadioForm
                    options={allParticipants}
                    chosen={participantFilter}
                    setChosen={(val) => {
                      setParticipantFilter(val)
                    }}
                    showOther={false}
                    otherValue={'q'}
                    keyPrefix={'participantFilter'}
                  />
                ) : null}
                {/* <div className="CreateFormDiv">
                        <div onChange={(value) => {setParticipantFilter(value.target.value)}} >
                                 <input type="radio" value={''} name={"participantFilterRadio"} className="inputCss" /> None
                                    <br/>
                           <div class="participantsFlex">
                                 {allParticipants.map((p, index) => {
                                    return (
                                       <div class="partItem">
                                          <input type="radio" value={p} name={"participantFilterRadio"} className="inputCss" 
                                             testid={`participantFilterButton_${p}`}
                                          /> {p} 
                                       </div> 
                                    )
                                 })}
                           </div>
                        </div>
                     </div> */}
              </Row>
              <Row style={{ paddingTop: 15, paddingBottom: 15 }}>
                <h4>By Client Name</h4>
                {CRs.length > 0 ? (
                  <RadioForm
                    options={CRs.map((x) => x.clientName)}
                    chosen={search}
                    setChosen={(val) => {
                      setSearch(val)
                    }}
                    showOther={false}
                    otherValue={'q'}
                  />
                ) : null}
              </Row>
            </div>
          </Col>
          <Col>
            <Row>
              <h1 className='Createh1'>Schedule Meetings</h1>
              <div className='bottomLine'></div>
              <p className='createFeedback'>{feedback}</p>
            </Row>

            {/* <Form className="SearchFormClass">
                     <div className="CreateFormDiv">
                     <Form.Group className="mb-3" controlId="formBasicEmail">
                     <h4>Exact Name to Search</h4>
                        <Form.Control type="text" placeholder="John Doe" value={search} testid='search_form'
                              onChange={(e) => setSearch(e.target.value)}
                        />
                     </Form.Group>
                     </div>
                     <br/>
                     <h4>Filter by Participant</h4>
                     <div className="CreateFormDiv">
                        <div onChange={(value) => {setParticipantFilter(value.target.value)}} >
                                 <input type="radio" value={''} name={"participantFilterRadio"} className="inputCss" /> None
                                    <br/>
                           <div class="participantsFlex">
                                 {allParticipants.map((p, index) => {
                                    return (
                                       <div class="partItem">
                                          <input type="radio" value={p} name={"participantFilterRadio"} className="inputCss" 
                                             testid={`participantFilterButton_${p}`}
                                          /> {p} 
                                       </div> 
                                    )
                                 })}
                           </div>
                        </div>
                     </div>
                     
               </Form> */}

            <Row>
              {/* <AddNewCRForm onClick={printDataOnClick}/> */}

              <Col className='ScheduleList_Container' style={{ borderRight: '1px solid #e6e6e6' }}>
                <div style={{ textAlign: '-webkit-center' }}>{loading ? <ReactLoading type={'spin'} color={secondaryCardColor} /> : null}</div>
                <div style={{ textAlign: 'center' }}>
                  {' '}
                  {filteredCRs.length == 0 && search.length > 0 ? (
                    <h4>
                      No {participantFilter == null ? null : participantFilter} meetings for "{search}"
                    </h4>
                  ) : null}{' '}
                </div>

                {!loading && filteredCRs.length < 1 ? (
                  <p style={{ fontSize: '1.2rem', color: 'grey', marginLeft: 20 }}>- none to Schedule -</p>
                ) : (
                  <>
                    {filteredCRs.filter((x) => x.dueDate < thirtyDaysOut && x.dueDate > new Date().getTime()).length > 0 ? (
                      <>
                        <Row>
                          <h2 className='Createh2'>This Month </h2>
                        </Row>
                        <Row>
                          <ToScheduleList searchVal={search} CRs={filteredCRs} refreshList={getList} type='thismonth' indexOffset={0} _style={{ padding: '1%' }} />
                        </Row>
                      </>
                    ) : null}

                    {filteredCRs.filter((x) => x.dueDate < new Date().getTime()).length > 0 ? (
                      <>
                        <Row>
                          <h2 className='Createh2'>Late </h2>
                          {/* TO DO: BUG:  There's a bug where when filtereing is applied, sometimes the last passes data on from filtered entries cause it's absolute cheeks */}
                        </Row>
                        <Row>
                          <ToScheduleList searchVal={search} CRs={filteredCRs} refreshList={getList} type='past' indexOffset={2000} _style={{ padding: '1%' }} />
                        </Row>
                      </>
                    ) : null}

                    {filteredCRs.filter((x) => x.dueDate > thirtyDaysOut).length > 0 ? (
                      <>
                        <Row>
                          <h2 className='Createh2'>In the future </h2>
                        </Row>
                        <Row>
                          <ToScheduleList searchVal={search} CRs={filteredCRs} refreshList={getList} type='future' indexOffset={1000} _style={{ padding: '1%' }} />
                        </Row>
                      </>
                    ) : null}
                  </>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Schedule_clientScreen
