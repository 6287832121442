import React, { useEffect, useState } from 'react';
import {Row, Col, Form, Button} from 'react-bootstrap';
import {Auth} from 'aws-amplify'

import './Login.css'


function LogInScreen(props) {
    const [feedback, setFeedback] = useState('');
    const [inputFeedback, setInputFeedback] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    async function tryLogin(_username, _password) {
        setFeedback(' ')
        console.log('trying login with: ', _username, _password)
        try {
            const user = await Auth.signIn(_username, _password);
            console.log('just signed in user: ', user);
            setFeedback('signing in: ' + user.username)
            props.onAuthenticate(true);
        } catch (error) {
            setFeedback('Incorrect Email or Password')
            console.log('Error using Auth Sign In :', error);
        }

    }

    // useEffect(() => {

    // }, [])

  return (
    <div className='App-topdiv'>
        <Row>
            <Col>
                <Row> 
                    <h1 className="Createh1">Login</h1>
                    <div className="bottomLine"></div> 
                </Row>

                <Row>
                    <p className="createFeedback" >{feedback}</p>
                </Row>

                <Row>
                    <div className="CreateFormDiv">
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" placeholder="JohnDoe@carystamp.com" value={username} testid='name_form'
                                onChange={(e) => setUsername(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" placeholder="JohnPass1!" value={password} testid='name_form'
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </Form.Group>

                        {/* <Form.Group className="mb-3" controlId="formBasicCheckbox">
                            <Form.Check type="checkbox" label="Check me out" />
                        </Form.Group> */}

                        <Button variant="outline-success" onClick={() => {   tryLogin(username, password)  }}>
                            Login
                        </Button>

                    </div>
                </Row>

                <Row>
                    <p className="inputFeedback">{props.feedback}</p>
                </Row>

            </Col>
        </Row>
    </div>
    );
}

export default LogInScreen;