///with new radio buttons

import React, { useEffect, useRef, useState } from 'react'
import { Row, Col, Button, Card, Form, Modal } from 'react-bootstrap'
import ReactDOM from 'react-dom'
import ReactLoading from 'react-loading'

import { Check } from 'react-feather'

import StageCheckMarks from '../../common/StageCheckMarks'
import { activeEndpoint, activeLocation, SchedulerEmail } from '../../common/FauxEnv'

import ChangeDateForm from '../../common/ChangeDateForm'

import { Auth } from 'aws-amplify'
import RadioForm from '../../common/RadioForm'

import { textColor, cardColor, secondaryCardColor, cornerRadius } from '../../common/style.js'

function ToReviewCard({ CR, index, refreshList }) {
  const [selectedParticipants, setSelectedParticipants] = React.useState([])
  const [selectedMedium, setSelectedMedium] = React.useState('none')
  const [selectedUrgency, setSelectedUrgency] = React.useState('none')
  const [feedback, setFeedback] = React.useState('')
  const [otherValue, setOtherValue] = React.useState('')
  const [otherUrgencyValue, setOtherUrgencyValue] = React.useState('')
  const [otherMediumValue, setOtherMediumValue] = React.useState('')
  const [noteText, setNoteText] = React.useState(CR.notes)

  const [showForm, setShowForm] = React.useState(false)
  const [showDeleteModal, setShowDeleteModal] = React.useState(false)

  const refT = useRef()

  const today = new Date()
  const [datesUntil, setDatesUntil] = useState(false)

  const ADVISORS = ['Cary Stamp', 'Jack Schanne', 'Ryan Swenson', 'Matt Mellusi', 'Austin Kuyrkendall', 'Rob Taylor']

  const ADMINS = ['Patrick Thompson', 'Shay Diaz', 'Sharon Stamp', 'Colleen Kunzelmann']

  // const [otherMedium, setOtherMedium] = React.useState('');
  // const [otherUrgency, setOtherUrgency] = React.useState('');
  // const [otherFeedback, setOtherFeedback] = React.useState('');

  useEffect(() => {
    setDatesUntil(Math.ceil((CR.dueDate - today.getTime()) / (1000 * 60 * 60 * 24)))
  }, [])

  useEffect(() => {
    if (feedback.indexOf('Successfully') !== -1) {
      setTimeout(() => {
        setFeedback('')
      }, 4000)
    }
  }, [feedback])

  function handleOtherChange(newOtherValue) {
    console.log('before parts', selectedParticipants)
    setOtherValue(newOtherValue)

    var elementLocation = -1
    //for each value in selectedParticipants
    for (let i = 0; i < selectedParticipants.length; i++) {
      if (selectedParticipants[i][0] === ' ' || selectedParticipants[i] === '') {
        //remove that selectedParticipant
        elementLocation = i
      }
    }
    console.log('elem Loc', elementLocation)
    if (elementLocation !== -1) {
      var withoutOld = selectedParticipants.splice(elementLocation, 1)
      setSelectedParticipants([...selectedParticipants, newOtherValue])
    }
  }

  async function updateCRNotes() {
    setFeedback('Saving...')

    var _keys = []
    var _values = []

    _keys.push('notes')
    _values.push(noteText)

    var myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append('Authorization', `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`)

    var raw = JSON.stringify({
      id: CR.id,
      updateKeys: _keys,
      updateValues: _values,
    })

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    }

    var fetchCode
    fetch(activeEndpoint + '/Meeting/' + CR.id, requestOptions)
      .then((response) => {
        fetchCode = response.status
        response.text()
      })
      .then((result) => {
        if (fetchCode === 200) {
          setFeedback('Successfully updated notes')
          console.log('result: ', result)
        } else {
          console.log('error: ', result, '\nwithCode: ', fetchCode)
          setFeedback('ErrorCode: ' + fetchCode)
        }
      })
      .catch((error) => console.log('error', error))
  }

  function handleOtherUrgencyChange(newOtherValue) {
    console.log('before parts', selectedUrgency)
    setOtherUrgencyValue(newOtherValue)

    console.log(selectedUrgency)
    if (selectedUrgency != 'none' && (selectedUrgency[0] === ' ' || selectedUrgency === '')) {
      setSelectedUrgency(newOtherValue)
    }
  }

  function handleOtherMediumChange(newOtherValue) {
    console.log('before parts', selectedMedium)
    setOtherMediumValue(newOtherValue)

    console.log(selectedMedium)
    if (selectedMedium != 'none' && (selectedMedium[0] === ' ' || selectedMedium === '')) {
      setSelectedMedium(newOtherValue)
    }
  }

  function handleRadioChange(value, otherValue, setFunction) {
    if (value === 'other') {
      setFunction(otherValue)
    } else {
      setFunction(value)
    }
  }

  useEffect(() => {
    // console.log('new parts', selectedParticipants)
  }, [selectedParticipants])

  async function handleDateUpdate(newDate) {
    setShowForm(false)
    setFeedback('changing date to...', new Date(newDate).toString().substr(0, 15))

    //add one month to CR.dueDate
    var newDueDate = new Date(newDate + ' 8:00 est').getTime()

    //update CR.dueDate
    var myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append('Authorization', `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`)

    var raw = JSON.stringify({
      id: CR.id,
      updateKeys: ['dueDate'],
      updateValues: [newDueDate],
    })

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    }

    var fetchCode
    fetch(activeEndpoint + '/Meeting/' + CR.id, requestOptions)
      .then((response) => {
        fetchCode = response.status
        response.text()
      })
      .then(async (result) => {
        if (fetchCode === 200) {
          setFeedback('Successfully updated Meeting')
          console.log('result: ', result)
          refreshList()
        } else {
          console.log('error: ', result, '\nwithCode: ', fetchCode)
          setFeedback('ErrorCode: ' + fetchCode)
        }
      })
      .catch((error) => console.log('error', error))
  }

  async function handleSendForSchedule() {
    if (selectedParticipants.length < 1) {
      setFeedback(`Please select at least one participant`)
      return
    } else if (selectedUrgency === 'none') {
      setFeedback(`Please select an urgency`)
      return
    } else if (selectedMedium === 'none') {
      setFeedback(`Please select a medium`)
      return
    } else {
      setFeedback('Sending...')
      updateCR()
    }
  }

  async function updateCR() {
    var myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append('Authorization', `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`)

    var raw = JSON.stringify({
      id: CR.id,
      updateKeys: ['stage', 'urgency', 'medium', 'participants', 'notes'],
      updateValues: [3, selectedUrgency, selectedMedium, selectedParticipants, noteText],
    })

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    }

    var fetchCode
    fetch(activeEndpoint + '/Meeting/' + CR.id, requestOptions)
      .then((response) => {
        fetchCode = response.status
        response.text()
      })
      .then(async (result) => {
        if (fetchCode === 200) {
          setFeedback('Successfully updated Meeting')
          CR.status = 3
          console.log('result: ', result)
          refreshList()
        } else {
          console.log('error: ', result, '\nwithCode: ', fetchCode)
          setFeedback('ErrorCode: ' + fetchCode)
        }
      })
      .catch((error) => console.log('error', error))
  }

  function handleDelete(CR, index) {
    console.log('delete: ' + CR.id)

    const doubleCheck = window.confirm('Are you sure you want to delete ' + CR.clientName + '?')
    if (doubleCheck) {
      newHandleDeleteCR(CR.id)
    }
  }

  async function handleSendDeleteNotification() {
    const recipient = SchedulerEmail
    const subject = `${CR.clientName} meeting has been deleted.`

    var myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append('Authorization', `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`)

    var raw = JSON.stringify({
      recipients: [recipient],
      subject: subject,
      message: `${subject}\n\n\tSomeone has deleted a meeting with ${CR.clientName} on ${new Date(CR.dueDate).toString().substr(0, 15)}.\n\tPlease verify that this was intentional.\n\n(This email is automated. Do not reply.)`,
    })

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    }

    var fetchCode
    fetch(activeEndpoint + '/email', requestOptions)
      .then((response) => {
        fetchCode = response.status
        response.text()
      })
      .then(async (result) => {
        if (fetchCode === 200) {
          console.log('email success')
        } else {
          console.log('delete failed with code: ', fetchCode)
        }
      })
      .catch((error) => console.log('delete Error', error))
  }

  // async function handleDeleteCR(id) {        //TO DO: DEBUG
  //     console.log("deleting:", id)

  //     //delete the list
  //     var myHeaders = new Headers();
  //     myHeaders.append("Content-Type", "application/json");
  //     myHeaders.append("Authorization", `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`)

  //     var requestOptions = {
  //         body: JSON.stringify({id: id}),
  //     headers: myHeaders,
  //     method: 'delete',
  //     redirect: 'follow'
  //     };

  //     var fetchCode
  //     fetch(activeEndpoint + '/Meeting/' + id, requestOptions)
  //     .then(response => {
  //         fetchCode = response.status
  //         return response.text()})
  //     .then(result => {
  //         console.log('deleteResults: '+ result)
  //         if(fetchCode === 200) {
  //             refreshList()
  //         }
  //         else {
  //             console.log('delete failed with code: ', fetchCode)
  //         }

  //     })
  //     .catch(error => console.log('delete Error', error));

  // }

  async function newHandleDeleteCR(id) {
    var myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append('Authorization', `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`)

    var raw = JSON.stringify({
      id: id,
      updateKeys: ['stage'],
      updateValues: [1000],
    })

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    }

    var fetchCode
    fetch(activeEndpoint + '/Meeting/' + id, requestOptions)
      .then((response) => {
        fetchCode = response.status
        response.text()
      })
      .then(async (result) => {
        console.log('deleteResults: ' + result)
        if (fetchCode === 200) {
          setShowDeleteModal(true)
          handleSendDeleteNotification()
          setTimeout(() => {
            refreshList()
          }, 1500)
        } else {
          console.log('delete failed with code: ', fetchCode)
        }
      })
      .catch((error) => console.log('delete Error', error))
  }

  return (
    <div
      style={{
        marginRight: 10,
        marginBottom: 100,
      }}
    >
      <Card
        key={CR.id}
        style={{
          marginBottom: 50,
          backgroundColor: secondaryCardColor,
          borderTopLeftRadius: cornerRadius,
          borderTopRightRadius: cornerRadius,
        }}
        id={CR.id}
        testid={'toReviewCard' + CR.clientName}
      >
        <div style={{ backgroundColor: cardColor, borderTopLeftRadius: cornerRadius, borderTopRightRadius: cornerRadius }}>
          <Row
            style={{
              alignItems: 'center',
            }}
          >
            <Col>
              <Card.Title>
                <h4 style={{ color: textColor }} className='scheduleCardh4'>
                  {CR.clientName}
                </h4>
              </Card.Title>
            </Col>
            <Col>
              <Card.Body style={{ color: textColor }}>
                <h4 className='scheduleCardh5'>
                  due {datesUntil >= 0 ? 'in' : null}{' '}
                  <b>
                    <strong>{Math.abs(datesUntil)}</strong>
                  </b>{' '}
                  days {datesUntil < 0 ? 'ago' : null}
                </h4>
                <h5 className='scheduleCardh5'>{new Date(CR.dueDate).toString().substr(0, 15)}</h5>
              </Card.Body>
            </Col>
            <Col xs={2} md={2} lg={2} xl={2} xxl={2}>
              <StageCheckMarks stage={CR.stage} textColor={textColor} />
            </Col>
          </Row>
        </div>
        <Row style={{}}>
          <Col>
            <div className='scheduleCardDetailsContainer'>
              <h4 className='scheduleCardDetailsh4'>Participants:</h4>
              <Form>
                <Col>
                  <Row>
                    <Col>
                      <h4 className='boldOneRem'>Advisors:</h4>
                      <RadioForm
                        options={ADVISORS}
                        chosen={selectedParticipants}
                        setChosen={(val) => {
                          setSelectedParticipants(!selectedParticipants.includes(val) ? [...selectedParticipants, val] : [...selectedParticipants.filter((x) => x !== val)])
                        }}
                        otherValue={' ' + otherValue}
                        cols={1}
                        showOther={false}
                        buttonStyleOverride={{ color: '#444' }}
                        buttonVarient={'info'}
                        keyPrefix={CR.clientName + '_participants'}
                      />

                      {/* {ADVISORS.map((name) => (
                                                    <div key={`default-${name}${index}`} className="mb-3 scheduleCardh5">
                                                    <Form.Check
                                                        type={'checkbox'}
                                                        id={`default-${name}${index}`}
                                                        label={`${name}`}
                                                        value={name}
                                                        testid={`participantCheckbox-${name}`+CR.clientName}
                                                        onChange={(e) => setSelectedParticipants(e.target.checked ? [...selectedParticipants, name] : [...selectedParticipants.filter(x => x !== name)])}
                                                    /> </div>))} */}
                    </Col>
                    <Col>
                      <h4 className='boldOneRem'>Admins:</h4>
                      <RadioForm
                        options={ADMINS}
                        chosen={selectedParticipants}
                        setChosen={(val) => {
                          setSelectedParticipants(!selectedParticipants.includes(val) ? [...selectedParticipants, val] : [...selectedParticipants.filter((x) => x !== val)])
                        }}
                        otherValue={' ' + otherValue}
                        cols={1}
                        showOther={false}
                        buttonStyleOverride={{ color: '#444' }}
                        buttonVarient={'info'}
                        keyPrefix={CR.clientName + '_participants'}
                      />
                    </Col>
                  </Row>

                  <div style={{ marginTop: 15 }}>
                    {selectedParticipants.includes(otherValue) ? (
                      <Button variant='info' className='RadioForm_Button' style={{ color: '#444' }} onClick={() => setSelectedParticipants([...selectedParticipants.filter((x) => x[0] !== ' ' || x === '')])}>
                        <small>●</small> {'Other'}
                      </Button>
                    ) : (
                      <Button variant='outline-info' className='RadioForm_Button' style={{ color: '#444' }} onClick={() => setSelectedParticipants([...selectedParticipants, otherValue])}>
                        <small>○</small> {'Other'}
                      </Button>
                    )}
                    <Form.Control style={{ margin: 10 }} size='sm' type='text' placeholder='Someone else...' onChange={(event) => handleOtherChange(' ' + event.target.value)} />
                  </div>
                </Col>
              </Form>
            </div>
          </Col>
          <Col>
            <div className='scheduleCardDetailsContainer'>
              <h4 className='scheduleCardDetailsh4'>Urgency:</h4>
              <div className='mb-3 scheduleCardh5'>
                <RadioForm
                  options={['ASAP', 'Next month or beyond']}
                  // options = {ADVISORS}
                  chosen={selectedUrgency}
                  setChosen={(val) => setSelectedUrgency(val)}
                  otherValue={otherUrgencyValue || ' '}
                  buttonStyleOverride={{ color: '#444' }}
                  buttonVarient={'info'}
                  keyPrefix={CR.clientName + '_urgency'}
                />

                <Form.Control size='sm' type='text' placeholder='Something else...' onChange={(event) => handleOtherUrgencyChange(' ' + event.target.value)} />
              </div>

              <h4 className='scheduleCardDetailsh4 '>Medium:</h4>
              <div className='mb-3 scheduleCardh5'>
                <RadioForm
                  options={['Call Client Directly', 'Conference Line', 'Video Call', 'Tequesta Office']}
                  // options = {ADVISORS}
                  chosen={selectedMedium}
                  setChosen={(val) => setSelectedMedium(val)}
                  otherValue={otherMediumValue || ' '}
                  buttonStyleOverride={{ color: '#444' }}
                  buttonVarient={'info'}
                  keyPrefix={CR.clientName + '_medium'}
                />
                <Form.Control size='sm' type='text' placeholder='Somehow else...' onChange={(event) => handleOtherMediumChange(' ' + event.target.value)} />
              </div>

              <h4 className='scheduleCardDetailsh4 '>Notes:</h4>
              <div className='notesContainer'>
                <Form>
                  <Form.Control as='textarea' rows={6} placeholder='Enter notes here' value={noteText} onChange={(e) => setNoteText(e.target.value)} testid={'notesArea' + CR.clientName} />
                </Form>
                <Button variant='outline-primary' onClick={() => updateCRNotes()} style={{ width: '7rem', marginTop: 10, justifySelf: 'center' }}>
                  Save Notes{' '}
                </Button>
              </div>
            </div>
            {/* debug stuff */}
            {/* <p>~{selectedMedium}~</p>
                                <p>`{selectedUrgency}`</p>
                                {selectedParticipants.map((type) => (
                                    <p key={type}>.{type}.</p>
                                ))} 
                                    <p>+{otherValue}+</p> */}
          </Col>
        </Row>
        <Row>
          <Col>
            <Row>
              <Col>
                <Button variant='success' onClick={() => handleSendForSchedule()} style={{ width: '11rem', margin: 10, justifySelf: 'center' }}>
                  Send for Scheduling{' '}
                </Button>
              </Col>
            </Row>
          </Col>
          <Col> </Col>
          <Col>
            <Row>
              <Col>
                {/* <Button variant="primary" onClick={() => handleMoveToNextMonth()}
                                        style={{ width: '11rem', margin: 10, justifySelf: 'center'}}
                                    >Move to next month </Button> */}

                <Button variant='primary' onClick={() => setShowForm(true)} style={{ width: '11rem', margin: 10, justifySelf: 'center' }}>
                  Change Due Date ...{' '}
                </Button>
                <Modal show={showForm} onHide={() => setShowForm(false)} testid='moveDateModal'>
                  <Modal.Body>
                    <ChangeDateForm clientName={CR.clientName.split(' ')[0]} onUpdate={handleDateUpdate} />
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant='secondary' onClick={() => setShowForm(false)}>
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>
              </Col>
            </Row>
          </Col>

          <Col> </Col>
          <Col>
            <Row>
              <Col>
                <Button variant='outline-danger' onClick={() => handleDelete(CR, index)} style={{ width: '11rem', margin: 10, justifySelf: 'center' }}>
                  Delete{' '}
                </Button>
                {/* <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)} testid='moveDateModal'>
                                        <Modal.Body>
                                            <h3>Deleted.  Shay will be notified</h3>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                                                Close
                                            </Button>
                                        </Modal.Footer>
                                    </Modal> */}
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
      {!(feedback == '' || feedback == null) ? (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: -50 }}>
          {feedback.indexOf('...') > -1 ? (
            <div style={{ flexDirection: 'column' }}>
              <ReactLoading type={'spin'} color='#a1a19f' height={20} width={20} />
            </div>
          ) : (
            <></>
          )}
          <div style={{ flexDirection: 'column' }}>
            <p className='reviewCardFeedbackText'>{feedback}</p>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}

function handleFormClick() {
  console.log('clicked')
}
function inputFeedback() {
  console.log('inputFeed')
}

export default ToReviewCard
