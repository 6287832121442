import React, {useCallback, useState, useEffect} from 'react'
import {Row, Col, Button, Card, Form, Modal} from 'react-bootstrap';
import {useDropzone} from 'react-dropzone'

import { cardColor, textColor } from '../../common/style'

import dragndrop from './dragndrop.png'

function MyDropzone(props) {

    const [fileJSONs, setFileJSONs] = useState([])

    const [showModal, setShowModal] = React.useState(false);


    useEffect(() => {
        // props.returnJSON(fileJSON)
    }, [fileJSONs])


  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader()

      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = () => {
            // Use reader.result
            var csv = reader.result;
            var lines = csv.split("\n");
            var result = [];
            var headers=lines[0].split(",");
            if(headers[0] == "Contact ID") {
                for(var i=1;i<lines.length;i++){
                    console.log("line" + i + ": " + lines[i])

                    //extract the value before the first comma
                    // var firstVal=lines[i].split(",")[0];
                    // obj[headers[0]]=firstVal;
                    //extract the value after the first comma
                    var lastname, firstname = null
                    lastname = lines[i].split(",")[1];
                    //replace " with ''
                    firstname = lines[i].split(",")[2];
                    // console.log('\n' + firstname + "===" + lastname)
                    
                    if(lastname && firstname){
                        lastname = lastname.replace(/"/g, '');
                        firstname = firstname.replace(/"/g, '');
                        // console.log('\n' + firstname + "=+=" + lastname)
                    }
                    else{
                        continue;
                    }

                    var obj = {};
                    var currentline=lines[i].split(",");
                    obj[headers[0]] = currentline[0];
                    obj[headers[1]] = firstname + "  " + lastname;
                    for(var j=3;j<headers.length;j++){
                        obj[headers[j-1]] = currentline[j];
                    }
                    result.push(obj);
                }  
            }
            if(headers[0] == "Contact Name") {
                for(var i=1;i<lines.length;i++){
                    console.log("line" + i + ": " + lines[i])

                    //extract the value before the first comma
                    // var firstVal=lines[i].split(",")[0];
                    // obj[headers[0]]=firstVal;
                    //extract the value after the first comma
                    var lastname, firstname = null
                    lastname = lines[i].split(",")[0];
                    //replace " with ''
                    firstname = lines[i].split(",")[1];
                    // console.log('\n' + firstname + "===" + lastname)
                    
                    if(lastname && firstname){
                        lastname = lastname.replace(/"/g, '');
                        firstname = firstname.replace(/"/g, '');
                        // console.log('\n' + firstname + "=+=" + lastname)
                    }
                    else{
                        continue;
                    }

                    var obj = {};
                    var currentline=lines[i].split(",");
                    // obj[headers[0]] = currentline[0];
                    obj[headers[0].trim()] = firstname + "  " + lastname;
                    obj[headers[1].trim()] = currentline[2]
                    result.push(obj);
                }  
            }
            //return result; //JavaScript object
            console.log('jsons from file:', result);

            var caryJsons = result.filter(function(json){
                if(json['Servicing Advisor'])
                    return json['Servicing Advisor'] == 'Cary Stamp'
                else
                    return true
            })

            setFileJSONs(caryJsons);
            // result = JSON.stringify(result); //JSON
            setShowModal(true);
      }
      reader.readAsText(file)
    })
    
  }, [])
  const {getRootProps, getInputProps} = useDropzone({onDrop})

  return (
      <>
    <div {...getRootProps()} style={{}} testid='fileDropzone'>
      <input {...getInputProps()} />
      {/* <p style={{fontSize: 13, color: 'purple', alignItems: 'center', textAlign: 'center' }}>Drag 'n' drop some files here, or click to select files</p> */}
      <img style={{alignSelf: 'center', borderColor: 'green', borderStyle: 'dotted', borderWidth: 2}} src={dragndrop} alt="dragndrop" />
    </div>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Body>
            <Col style={{padding: 6}}>
                <h2 className="Changeh2">Confirm that the following data is accurate:</h2>
                {fileJSONs.length < 1 ? <></> : 
                    fileJSONs.map((item, index) => {
                        return (
                            <div style={{ display: 'flex', flexDirection: 'row',  justifyContent: 'space-between'}} key={index}>
                                <Card 
                                    key={index} 
                                    style={{  
                                        marginRight: '10px', 
                                        marginBottom: '10px', 
                                        backgroundColor: cardColor,
                                        flexBasis: 500,
                                        paddingRight: '10px',
                                    }}
                                >
                                    <Row
                                        style={{  alignItems: 'center',  }}
                                    >
                                        <Col>
                                            <Card.Title>
                                                <h4 style={{textAlign: 'left', marginLeft: 7, color: textColor}} className='outstandingCardh4'>{item['Contact Name']}</h4>
                                            </Card.Title>
                                        </Col>
                                        <Col 
                                        >
                                            <Card.Body  >
                                                <h5 style={{color:textColor}} className='outstandingCardh5'>due: {new Date(new Date((item['Next Review Date']) + ' 8:00 est').getTime()).toString().substr(0,15)}</h5>
                                            </Card.Body>
                                        </Col>

                                    </Row>
                                    
                                </Card>

                            </div>
                        )
                    })                
                }
            </Col>
        </Modal.Body>
        <Modal.Footer>
        <Button variant="primary" onClick={() => {
            props.returnJSON(fileJSONs)
            setShowModal(false)
        }}>
            Looks Good!
        </Button>
        <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
        </Button>
        </Modal.Footer>
    </Modal>
    </>
  )
}

export default MyDropzone;