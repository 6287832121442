import React, { useEffect, useState } from 'react';
import {Card, Col, Row, Dropdown} from 'react-bootstrap';
// import { ChevronDown} from 'react-feather';
import ReactLoading from 'react-loading';

import { secondaryCardColor } from '../../common/style';

import {Auth} from 'aws-amplify';
import StageCheckMarks from '../../common/StageCheckMarks'
import { activeEndpoint, activeLocation } from '../../common/FauxEnv'
import { textColor, cardColor } from '../../common/style';


 function OutstandingCRList({CRs, _style, onDeleteCR, loading}) {

    const [orderedCRs, setOrderedCRs] = useState(CRs);

    useEffect(() => {
        var tmp = CRs
        //sort by oldest first
        tmp.sort((a,b) => {
            return new Date(a.dueDate) - new Date(b.dueDate)
        })

        setOrderedCRs(tmp)
    }, [CRs])


    function handleDelete(CR, index) {
        console.log('delete: ' + CR.id);
        
        const doubleCheck = window.confirm('Are you sure you want to delete '+ CR.clientName + '?');
        if(doubleCheck) {
            newHandleDeleteCR(CR.id)
        }

    }

    // async function handleDeleteCR(id) {        //TO DO: DEBUG
    //     console.log("deleting:", id)
              
    //     //delete the list
    //     var myHeaders = new Headers();
    //     myHeaders.append("Content-Type", "application/json");
    //     myHeaders.append("Authorization", `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`)


    //     var requestOptions = {
    //         body: JSON.stringify({id: id}),
    //         headers: myHeaders,
    //         method: 'delete',
    //         redirect: 'follow',
    //     };

    //     var fetchCode
    //     fetch(activeEndpoint + '/Meeting/' + id, requestOptions)
    //       .then(response => {
    //         fetchCode = response.status
    //         return response.text()})
    //       .then(result => {
    //           console.log('deleteResults: '+ result)  
    //           if(fetchCode === 200) {
    //             onDeleteCR(id)
    //           }
    //           else {
    //               console.log('delete failed with code: ', fetchCode)
    //           }

    //       })
    //       .catch(error => console.log('delete Error', error));
        
    // }

    async function newHandleDeleteCR(id) {

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`)

        var raw = JSON.stringify({
            "id": id,
            "updateKeys": ["stage"],
            "updateValues": [1000]
        });

        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        };

        var fetchCode
        fetch(activeEndpoint + '/Meeting/' + id, requestOptions)
          .then(response => {
             fetchCode = response.status
             response.text()})
          .then(async (result) => {
            console.log('deleteResults: '+ result)  
            if(fetchCode === 200) {
                onDeleteCR(id)
            }
            else {
                console.log('delete failed with code: ', fetchCode)
            }
          })
          .catch(error => console.log('delete Error', error));

    }

    function handleClick(CR) {
            if(CR.stage == 3) {
                window.location.href = ( activeLocation + 'schedule/' +"?search=" + CR.clientName)
            }
            if(CR.stage == 2) {
                window.location.href = (activeLocation + 'review/' +"?search=" + CR.clientName)
            }
    }

  return (
      <Col style={_style}>
        {loading ? 
            <div style={{textAlign: '-webkit-center'}}>
                <ReactLoading type={'spin'} color={secondaryCardColor} /> 
            </div>
         : 
        <>
            {CRs.length > 0 ? 
                CRs.map((CR, index) => {
                if(CR.stage < 100) {
                return(
                    <div style={{ display: 'flex', flexDirection: 'row',  justifyContent: 'space-between'}} key={'div'+ CR.id}>
                    <Card 
                        key={CR.id} 
                        testid="outstandingCard"
                        style={{  
                            marginRight: '10px', 
                            marginBottom: '10px', 
                            backgroundColor: cardColor,
                            flexBasis: 550,      
                        }}
                    >
                        <Row 
                            style={{  alignItems: 'center',  }}
                        >
                            <Col onClick={() => handleClick(CR)}>
                                <Card.Title>
                                    <h4 style={{textAlign: 'left', marginLeft: 7, color: textColor}} className='outstandingCardh4' testid="outstandingCardClientName"
                                    >{CR.clientName}</h4>
                                </Card.Title>
                            </Col>
                            <Col onClick={() => handleClick(CR)}
                            >
                                <Card.Body  >
                                    <h5 style={{color: textColor}} className='outstandingCardh5'>due: {new Date(CR.dueDate).toString().substr(0,15)}</h5>
                                </Card.Body>
                            </Col>
                            <Col  onClick={() => handleClick(CR)}
                                xs={2}
                                md={2}
                                lg={2}
                                xl={2}
                                xxl={2}
                            >
                                <StageCheckMarks stage={CR.stage} textColor={textColor} />
                            </Col>
                            <Col 
                                xs={2}
                                md={2}
                                lg={2}
                                xl={2}
                                xxl={2}
                            >
                                <Dropdown>
                                    <Dropdown.Toggle variant="outline-light" testid={'dropdown' + CR.clientName}>
                                        {/* <ChevronDown color="black" size={20} style={{alignSelf: 'center'}} testid={'delete' + CR.clientName}/> */}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item testid={'delete' + CR.clientName} href="#/action-1" onClick= {()=> handleDelete(CR, index)}>Delete</Dropdown.Item>
                                        {/* <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                        <Dropdown.Item href="#/action-3">Something else</Dropdown.Item> */}
                                    </Dropdown.Menu>
                                </Dropdown> 
                                
                            </Col>
                            
                        </Row>
                        
                        
                    </Card>
                    
                    
                    </div>

                    // <div key={index}>
                    //    <h3>{index}</h3>
                    //    <p>author: {CR.author}</p>
                    //    <p>client: {CR.clientName}</p>
                    // </div>
                )} else {return( <></>)}
                
                })
                :
                <p style={{fontSize: '1.2rem', color: 'grey', marginLeft: 20}}>- none awaiting scheduling -</p>
            }
        </>
        }
        </Col>
    );
}

export default OutstandingCRList;