import React, { useEffect, useState } from 'react';
import {Row, Col, Button, Form} from 'react-bootstrap';

// import './ChangeDateCSS.css'


function MarkAsScheduledForm(props) {

    const [feedback, setFeedback] = useState('');

    const [data, setData] = useState({
        name: '',
        dueDate: new Date()
    });

    function onSaveClick () {
        if(data.dueDate === '') {
            setFeedback('Please enter a due date');
            return;
        }
     
        props.onSaveClick(data);
    }


    return (
        <Col style={{padding: '7%'}}>
            <Row>
                <h2 className="Changeh2">{props.clientName}'s meeting has been scheduled for...</h2>
            </Row>
            <Row>
            <Form className="CreateFormClass">
                <div className="CreateFormDiv">

                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Meeting Date</Form.Label>
                    <Form.Control type="date" placeholder="Date"  testid='move_date_form'
                        value={data.dueDate}
                        onChange={(e) => setData({...data, dueDate: e.target.value})}
                    />
                </Form.Group>

                <Button variant="primary" onClick={() => {   props.onUpdate(data.dueDate);  }}>
                    {props.confirmButtonText? props.confirmButtonText : 'Save'}
                </Button>

                <p className="inputFeedback">{feedback}</p>
                </div>
            </Form>
            </Row>
            {/* <Button variant="success" onClick={() => {   props.onClick(data);  }}
                >Sumbit</Button> */}
        </Col>
    );


}

export default MarkAsScheduledForm;