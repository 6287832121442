import React, { useEffect, useState } from 'react';
import {Row, Col} from 'react-bootstrap';
import { BrowserRouter, Route, Switch, useParams, useLocation } from 'react-router-dom';

import { Auth } from 'aws-amplify';

import CaryNavBar from '../Navigation/CaryNavBar'
import LogInScreen from '../Auth/LogInScreen';

import CreateClientReviewScreen from './create/CreateScreen';
import ReviewScreen from './review/ReviewScreen';
import ScheduleClientScreen from './schedule/ScheduleScreen';
import PrepareScreen from './prepare/PrepareScreen';
import CompletedScreen from './completed/CompletedScreen';
import ClientAgendaForm from './Unauth/ClientAgendaForm';
import AgendaPDFBuilder from './prepare/AgendaPDFBuilder';

import {overallFont} from '../common/style.js'


 function ClientReviewMainScreen() {

    const [awsUser, setAwsUser] = useState(null);


    useEffect(() => {
      tryToGetAWSUser(true)
        
    }, [])

    function tryToGetAWSUser(goIfTrue) {
      if(goIfTrue) {
          Auth.currentAuthenticatedUser({
            bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
          }).then(user => {
              console.log('ClientReviewMainScreen | current AWS user:', user)
              setAwsUser(user);
          })
          .catch(err => {
            console.log('um..', err)
            setAwsUser(null);
          });
      }
    } 


     return (
        <div style={{fontFamily: overallFont}}>
          {awsUser != null ? 
            <BrowserRouter>
              <CaryNavBar onLogout={tryToGetAWSUser} awsUser={awsUser}/>
              <Switch>
                                
                <Route exact path="/review" component={ReviewScreen}/>
                
                <Route exact path="/schedule" component={ScheduleClientScreen}/>

                <Route exact path="/prepare" component={PrepareScreen}/>

                <Route exact path="/agenda/:id" component={AgendaPDFBuilder}/>

                <Route exact path="/completed" component={CompletedScreen}/>

                {/* <Route exact path="/create" /> */}
                <Route path="/" component={CreateClientReviewScreen}/>

              </Switch>
            </BrowserRouter>  
          : <LogInScreen onAuthenticate={tryToGetAWSUser}/>}
        </div>
      );
}

function ClientReviewMainScreenUnAuthed () {
  return (
    <div >
      <BrowserRouter>
        <Switch>
          <Route exact path="/meeting-agenda-builder/:id" component={ClientAgendaForm}/>

          <Route path="/" component={ClientReviewMainScreen}/>
        </Switch>
      </BrowserRouter>  
    </div>
  );
}



export default ClientReviewMainScreenUnAuthed;