import React, { useEffect, useState } from 'react';
import {Row, Col, Button, Form} from 'react-bootstrap';

import './RadioFormCSS.css'
// import FollowUp from './FollowUp';


/**
 * Insert text at cursor position.
 * key and testid are props.keyPrefix?_radioForm_option
 *
 * @prop options: array of button titles
 * @prop chosen: string of the chosen button
 * @prop onChosen: function to call when a button is clicked (with string of title as val)
 * @prop otherValue: the string to return when "Other" is pressed
 * @prop showOther: boolean to show "Other" button
 * @prop cols: number of columns to arrange the buttons in
 * @prop buttonVarient: what varient of the buttons you want these to be
 * @prop buttonStyleOverride: what style you want the buttons to be
 * @prop keyPrefix: string to prefix all keys with
 * @prop showReady: boolean to show "Ready" button
 * @public
 */
function RadioForm(props) {

    const [options, setOptions] = useState(props.options ? props.options : ['a', 'b', 'c', 'd']);


    if (props.displayGrid) {
        return (
            <Col className='RadioForm_Container'>
                <Row>
                        <div className='RadioFormRowGrid'>

                            {options.map((option, index) => {
                                return (
                                    // <Col md="auto" testid={(props.keyPrefix ? props.keyPrefix+"_": "")+ "radioform_"+option} key={(props.keyPrefix ? props.keyPrefix+"_": "")+ "radioform_"+option} className="RadioFormItems">
                                    <>
                                    {props.chosen.includes(option) ?
                                        <Button
                                            variant={props.buttonVarient ? props.buttonVarient : "secondary"}
                                            className="RadioForm_Button"
                                            style={{...props.buttonStyleOverride, gridColumn: 1}}
                                            onClick={() => {props.setChosen(option)}}
                                        >
                                            <small>●</small> {option}
                                        </Button>     
                                        :
                                        <Button
                                            variant={"outline-" + (props.buttonVarient ? props.buttonVarient : "secondary")}
                                            className="RadioForm_Button"
                                            style={{...props.buttonStyleOverride, gridColumn: 1}}
                                            onClick={() => {props.setChosen(option)}}
                                        >
                                            <small>○</small> {option}
                                        </Button>

                                    }
                                    {props.chosen.includes(option) && props.showPrepared ? 
                                        <>
                                            {props.chosen.includes(option+"prepared") ?
                                            <Button
                                                variant={ "secondary"}
                                                className="RadioForm_ButtonPrepared"
                                                // style={props.buttonStyleOverride}
                                                style={{gridColumn: 2, display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                                                onClick={() => {props.setChosen(option+"prepared")}}
                                            >
                                            <small>●</small> Prepared
                                            </Button>
                                            :

                                            <Button
                                                variant={"outline-" + ("secondary")}
                                                className="RadioForm_ButtonPrepared"
                                                // style={props.buttonStyleOverride}
                                                style={{gridColumn: 2, display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                                                onClick={() => {props.setChosen(option+"prepared")}}
                                            >
                                                <small>○</small> Prepared
                                            </Button>
                                            }
                                        </>
                                    : <></>
                                    }
                                    </>
                                )
                            })}
                        </div>
                        {(props.showOther != undefined ? props.showOther : true) ?
                        <Row style={{marginBottom:5}}>
                                <Col testid={(props.keyPrefix ? props.keyPrefix+"_": "")+ "radioformOther"} key={(props.keyPrefix ? props.keyPrefix+"_": "")+ "radioform_Other"}>
                                {props.chosen != '' && (props.chosen == " " || "" + props.chosen == props.otherValue) ?
                                    <Button variant="info" className="RadioForm_Button" style={{color: '#444'}}
                                    onClick={() => {props.setChosen('none')}}
                                    >
                                        <small>●</small> Other
                                    </Button>

                                :
                                    <Button variant="outline-info" className="RadioForm_Button" style={{color: '#444'}}
                                        onClick={() => {props.setChosen(props.otherValue)}}
                                    >
                                    <small>○</small> Other
                                    </Button>
                                }
                                </Col>

                        </Row>
                        : null}
                </Row>
            </Col>
        );


    } else {


        return (
            <Col className='RadioForm_Container'>
                <Row>
                        <Row className='RadioFormRow' lg={{'cols': props.cols}}>
    
                            {options.map((option, index) => {
                                return (
                                    <Col md="auto" testid={(props.keyPrefix ? props.keyPrefix+"_": "")+ "radioform_"+option} key={(props.keyPrefix ? props.keyPrefix+"_": "")+ "radioform_"+option} className="RadioFormItems">
                                    {props.chosen.includes(option) ?
                                        <Col>
                                        <Button
                                            variant={props.buttonVarient ? props.buttonVarient : "secondary"}
                                            className="RadioForm_Button"
                                            style={props.buttonStyleOverride}
                                            onClick={() => {props.setChosen(option)}}
                                        >
                                            <small>●</small> {option}
                                        </Button>
                                        {props.showPrepared ? 
                                        <Row className='RadioForm_ButtonPrepared'>
    
                                            {props.chosen.includes(option+"prepared") ?
                                            <Button
                                                variant={ "secondary"}
                                                className="RadioForm_ButtonPrepared"
                                                // style={props.buttonStyleOverride}
                                                onClick={() => {props.setChosen(option+"prepared")}}
                                            >
                                                <small>●</small> Prepared
                                            </Button>
                                            :
    
                                                <Button
                                                    variant={"outline-" + ("secondary")}
                                                    className="RadioForm_ButtonPrepared"
                                                    // style={props.buttonStyleOverride}
                                                    onClick={() => {props.setChosen(option+"prepared")}}
                                                >
                                                    <small>○</small> Prepared
                                                </Button>
                                            }
                                        </Row> : <></>}
                                        </Col>
                                    :
    
                                        <Button
                                            variant={"outline-" + (props.buttonVarient ? props.buttonVarient : "secondary")}
                                            className="RadioForm_Button"
                                            style={props.buttonStyleOverride}
                                            onClick={() => {props.setChosen(option)}}
                                        >
                                             <small>○</small> {option}
                                        </Button>
                                    }
                                    </Col>
                                )
                                
                            })}
                        </Row>
                        {(props.showOther != undefined ? props.showOther : true) ?
                        <Row style={{marginBottom:5}}>
                                <Col testid={(props.keyPrefix ? props.keyPrefix+"_": "")+ "radioformOther"} key={(props.keyPrefix ? props.keyPrefix+"_": "")+ "radioform_Other"}>
                                {props.chosen != '' && (props.chosen == " " || "" + props.chosen == props.otherValue) ?
                                    <Button variant="info" className="RadioForm_Button" style={{color: '#444'}}
                                    onClick={() => {props.setChosen('none')}}
                                    >
                                        <small>●</small> Other
                                    </Button>
    
                                :
                                    <Button variant="outline-info" className="RadioForm_Button" style={{color: '#444'}}
                                        onClick={() => {props.setChosen(props.otherValue)}}
                                    >
                                       <small>○</small> Other
                                    </Button>
                                }
                                </Col>
    
                        </Row>
                        : null}
                </Row>
            </Col>
        );
    }



}

export default RadioForm;