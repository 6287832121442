import React from 'react'
import { Document as DocxDocument, Paragraph, Packer, TextRun, AlignmentType, Media, ImageRun, Numbering, AbstractNumbering, LevelFormat } from 'docx'
import { saveAs } from 'file-saver'
import { Button } from 'react-bootstrap'
import { imageBase64Data } from '../../common/csLogoInverted.js'

export default function AgendaDocxBuilder(props) {
  const createDocx = () => {
    const meetingData = props.meetingData

    const doc = new DocxDocument({
      sections: [
        {
          children: [
            new Paragraph({
              children: [
                new ImageRun({
                  data: Buffer.from(imageBase64Data, 'base64'),
                  transformation: {
                    width: 450,
                    height: 60,
                  },
                }),
              ],
              alignment: AlignmentType.CENTER,
            }),
            new Paragraph({}),
            new Paragraph({}),
            new Paragraph({
              children: [
                new TextRun({
                  text: `${meetingData.clientName} - ${new Date(meetingData.meetingDate).toString().substr(4, 11)}`,
                  //   bold: true,
                  underline: true,
                  size: 44,
                  font: 'Helvetica',
                }),
              ],
              alignment: AlignmentType.CENTER,
            }),
            new Paragraph({}),
            new Paragraph({}),
            new Paragraph({
              children: [
                new TextRun({
                  text: `PARTICIPANTS:`,
                  size: 26,
                  font: 'Helvetica',
                  //grey
                  color: '#808080',
                }),
              ],
              alignment: AlignmentType.CENTER,
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: `${meetingData.participants.join(', ')}`,
                  size: 26,
                  font: 'Helvetica',
                }),
              ],
              alignment: AlignmentType.CENTER,
            }),

            new Paragraph({}),
            new Paragraph({
              children: [
                new TextRun({
                  text: 'Agenda',
                  size: 30, // 12pt font
                  font: 'Helvetica',
                  color: '#808080',
                }),
              ],
              alignment: AlignmentType.CENTER,
            }),
            new Paragraph({}),
            new Paragraph({}),
            // ...meetingData.agendaItems
            //   .filter((item) => item.substring(item.length - 8) != 'prepared')
            //   .map(
            //     (item, index) =>
            //       new Paragraph({
            //         children: [
            //           new TextRun({
            //             text: `${index + 1}. ${item}`,
            //             size: 30, // 15pt font
            //           }),
            //         ],
            //         alignment: AlignmentType.LEFT,
            //       })
            //   ),
            ...meetingData.agendaItems
              .filter((item) => item.substring(item.length - 8) != 'prepared')
              .map((item, index) => {
                return [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: `${index + 1}. ${item}`,
                        size: 30, // 15pt font
                      }),
                    ],
                    // numbering: {
                    //   reference: numbering,
                    //   level: 0,
                    // },
                    alignment: AlignmentType.LEFT,
                  }),
                  new Paragraph({}),
                  new Paragraph({}),
                ]
              })
              .flat(),

            new Paragraph({}),
            new Paragraph({}),
            new Paragraph({}),
            new Paragraph({}),
            new Paragraph({}),
            new Paragraph({}),
            new Paragraph({}),
            new Paragraph({}),
            new Paragraph({}),
            new Paragraph({}),
            new Paragraph({}),
            new Paragraph({}),
            new Paragraph({}),
            meetingData.clientNote
              ? new Paragraph({
                  children: [
                    new TextRun({
                      text: 'Client Notes',
                      size: 30, // 12pt font
                      font: 'Helvetica',
                      color: '#808080',
                    }),
                  ],
                  alignment: AlignmentType.CENTER,
                })
              : new Paragraph({}),
            meetingData.clientNote
              ? new Paragraph({
                  children: [
                    new TextRun({
                      text: `"${meetingData.clientNote ?? ''}"`,
                      size: 24, // 12pt font
                      bold: true,
                    }),
                  ],
                  alignment: AlignmentType.CENTER,
                })
              : new Paragraph({}),
            new Paragraph({}),
            new Paragraph({}),
            new Paragraph({}),
            new Paragraph({
              children: [
                new TextRun({
                  text: 'Securities and advisory services offered through Commonwealth Financial Network, Member FINRA/SIPC, a registered investment advisor. Fixed insurance products and services offered through Cary Stamp & Company and CES Insurance Agency.',
                  size: 16,
                  italic: true,
                  color: '#808080',
                }),
              ],
              alignment: AlignmentType.CENTER,
            }),
          ],
        },
      ],
    })

    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, `Agenda_${meetingData.clientName}_${new Date(meetingData.meetingDate).toString().substr(4, 11)}.docx`)
    })
  }

  return (
    <Button variant='outline-warning' onClick={createDocx}>
      Download Agenda Doc
    </Button>
  )
}
