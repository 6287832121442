import React, { useEffect, useState } from 'react'
import { Row, Col, Card, Container, Button } from 'react-bootstrap'
import { Auth } from 'aws-amplify'

import { activeEndpoint, activeLocation, ReviewerEmail } from '../../common/FauxEnv'

import AddNewCRForm from './AddNewCRForm'
import OutstandingCRList from './OutstandingCRList'
import FileDropzone from './FileDropzone'

import './CreateScreen.css'

function CreateClientReviewScreen() {
  const [CRs, setCRs] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [awsUser, setAwsUser] = useState('')
  const [feedback, setFeedback] = useState('') //todo: change this to a toast
  const [inputFeedback, setInputFeedback] = useState('')

  useEffect(() => {
    console.log('hey')
    getList()

    Auth.currentAuthenticatedUser({
      bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    })
      .then((user) => {
        console.log('ClientReviewMainScreen | current AWS user:', user)
        setAwsUser(user.attributes.email)
      })
      .catch((err) => console.log(err))
  }, [])

  async function getList() {
    var myHeaders = new Headers()
    //myHeaders.append("Content-Type", "application/json");
    myHeaders.append('Authorization', `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`)

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      mode: 'cors',
      body: JSON.stringify({
        stage: 3,
      }),
    }

    var fetchCode
    fetch(activeEndpoint + '/Meeting/stage', requestOptions)
      .then((response) => {
        fetchCode = response.status
        return response.text()
      })
      .then((result) => {
        if (fetchCode === 200) {
          const parsed = JSON.parse(result)
          console.log('parsed: ', parsed)
          setCRs(parsed.CRs)
          setIsLoading(false)
        } else {
          console.log('error: ', result, '\nwithCode: ', fetchCode)
          setFeedback('ErrorCode: ' + fetchCode)
        }
      })
      .catch((error) => console.log('error', error))
  }

  async function createCRFromJSONs(jsons) {
    console.log('CREATE SCREEN 2 RECIEVED: ', jsons)

    //to do: call createNewCR for each json in jsons
    for (var i = 0; i < jsons.length; i++) {
      createNewCR(jsons[i]['Contact Name'], new Date(jsons[i]['Next Review Date'] + ' 8:00 est').getTime())
    }
  }

  async function createNewCR(client, dueDate, leadAdvisor = null) {
    var myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append('Authorization', `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`)

    var raw = JSON.stringify({
      author: awsUser,
      clientName: client,
      dueDate: dueDate,
      participants: [],
      leadAdvisor,
      urgency: '',
      medium: '',
      notes: '',
      clientId: 'unassigned',
    })

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
      mode: 'cors',
    }

    var fetchCode
    fetch(activeEndpoint + '/Meeting', requestOptions)
      .then((response) => {
        fetchCode = response.status
        response.text()
      })
      .then((result) => {
        if (fetchCode === 200) {
          setFeedback('Successfully created new Meeting')
          getList()
          console.log('result: ', result)
        } else {
          console.log('error: ', result, '\nwithCode: ', fetchCode)
          setFeedback('ErrorCode: ' + fetchCode)
        }
      })
      .catch((error) => console.log('error', error))
  }

  function handleFormClick(data) {
    if (data.name == '') {
      setInputFeedback('Please enter a name')
      return
    } else if (data.dueDate.length != 10) {
      setInputFeedback('Please enter a valid date')
      return
    } else {
      setInputFeedback('')
    }

    data.dueDate = new Date(data.dueDate + ' 8:00 est').getTime()

    console.log('data: ', data)

    setFeedback('Submitting...')
    createNewCR(data.name, data.dueDate)
  }

  function handleEmailClick() {
    var CRsToCount = CRs
    //filter to only what cary needs to review
    CRsToCount = CRsToCount.filter((CR) => CR.stage == 2)
    //filter to only those that are due this month
    CRsToCount = CRsToCount.filter((x) => new Date(x.dueDate).toString().substr(4, 3) == new Date().toString().substr(4, 3))

    if (CRsToCount.length == 0) {
      setFeedback('No CRs due this month to remind Cary about')
    } else {
      var emailBody = 'Go here to review them: ' + activeLocation + 'review' + ' %0a%0aMeetings to Review:'

      CRsToCount.forEach((CR) => {
        emailBody += '%0a%0a%09' + CR.clientName + ' - due: ' + new Date(CR.dueDate).toString().substr(0, 15)
      })

      // setFeedback('Cary Email: ' + CRsToCount.length.toString())
      window.location = 'mailto:' + ReviewerEmail + '?subject=You have ' + CRsToCount.length + ' meetings to review&body=' + emailBody
    }
  }

  return (
    <div className='App-topdiv' style={{ justifyContent: 'flex-start' }}>
      <Container fluid style={{ alignSelf: 'flex-start' }}>
        <Row>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-end' }}>
            <h1 className='Createh1'>Create Meeting</h1>
            {/* <Button variant="outline-primary" onClick={() => {  handleEmailClick()}}
                  style={{  height: 40, margin: 10 }}
               > Send Email Reminder to Cary </Button>         */}
          </div>

          <div className='bottomLine'></div>
          <p className='createFeedback'>{feedback}</p>
        </Row>
        <Row>
          <Col>
            <Row>
              <AddNewCRForm onClick={handleFormClick} feedback={inputFeedback} />
            </Row>
            <Row style={{ padding: 30 }}>
              <h2 className='Createh2'>Or use file</h2>

              <FileDropzone returnJSON={createCRFromJSONs} />
            </Row>
          </Col>

          <Col className='AddNewCRForm_Container' style={{ borderLeft: '1px solid #e6e6e6' }}>
            <Row>
              <h2 className='Createh2'>Awaiting Scheduling</h2>
            </Row>
            <Row>
              <OutstandingCRList CRs={CRs} onDeleteCR={getList} _style={{ padding: '1%' }} loading={isLoading} />
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default CreateClientReviewScreen
