import React, { useEffect, useState } from 'react';
import {Row, Col} from 'react-bootstrap';
import { Check } from 'react-feather';

import './StageCheck.css'

 function StageCheckMarks({stage, textColor}) {

    const checksize = 15;

  return (
        <div title={
            stage == '1' ? 'Just Created' :
            (stage == '2' ? 'Awaiting Review' :
            (stage == '3' ? 'Awaiting Schedule' :
            (stage == '4' ? 'Preparing for Meeting' :
            (stage == '100' ? 'Meeting Completed' :
            ''))))

        } //data continues over to card on refresh of review
        style={{
            width: 75,
            
            // backgroundColor: 'pink',
        }}>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
             }} >
                <div style={{
                    fontSize: checksize -3,
                    color: textColor,
                }}>
                {stage == '1' ? 'Just Created' :
                (stage == '2' ? 'Awaiting Advisor Review' :
                (stage == '3' ? 'Awaiting Scheduling' :
                (stage == '4' ? 'Preparing for Meeting' :
                (stage == '100' ? 'Meeting Completed' :
                ''))))}
                </div>
                <div 
                style={{
                    display: 'flex',
                    flexDirection: 'row'
                }}
                >

                    {/* for stage 3 and on */}
                    {stage > 2 ?
                        <Check size={checksize} color="#00bcd4" />
                    : null}

                    {/* for stage 4 and on */}
                    {stage > 3 ?
                        <Check size={checksize} color="#d3a138"/>
                    : null}

                    {/* for completed (stage 100) */}
                    {stage > 99 ?
                        <Check size={checksize} color="#00bcd4"/>
                    : null}

                </div>
            {/* <span class="tooltiptext">Tooltip text</span> */}
            </div>


        </div>
    );
}

export default StageCheckMarks;