import React, { useEffect } from 'react';
import {Button, Card, Col, Row, Form} from 'react-bootstrap';
import { Check } from 'react-feather';

import './ScheduleScreen.css'
import ToScheduleCard from './ToScheduleCard';

 function ToScheduleList({CRs, _style, refreshList, type, searchVal, indexOffset}) {

    const [filteredCRs, setFilteredCRs] = React.useState([]);
    const [morefilteredCRs, setMorefilteredCRs] = React.useState([]);

    useEffect(() => {
        console.log("ToScheduleList useEffect");
        if(searchVal === '' || searchVal === undefined || searchVal === null) {
            setMorefilteredCRs(filteredCRs);
        } else {
            setMorefilteredCRs(filteredCRs.filter(cr => cr.clientName.toLowerCase().includes(searchVal.toLowerCase())))
        }
    }, [searchVal, filteredCRs])

    useEffect(() => {
        var thirtyDaysOut = (new Date().getTime() + (86400 * 1000 * 30));
        if(type == 'thismonth') {
            let month = CRs.filter(
                x => x.dueDate < thirtyDaysOut && x.dueDate > new Date().getTime()
            )
           
            //sort by oldest first
            month.sort((a,b) => {
                return new Date(a.dueDate) - new Date(b.dueDate)
            })

            setFilteredCRs(month)
        }
        else if(type == 'future') {
            let notMonth = CRs.filter(
                x => x.dueDate > thirtyDaysOut && x.dueDate > new Date().getTime()
            )
            
            //sort by oldest first
            notMonth.sort((a,b) => {
                return new Date(a.dueDate) - new Date(b.dueDate)
            })

            setFilteredCRs(notMonth)
        }
        else if(type == 'past') {
            let past = CRs.filter(
                x =>  x.dueDate < new Date().getTime()
            )
            
            //sort by oldest first
            past.sort((a,b) => {
                return new Date(a.dueDate) - new Date(b.dueDate)
            })

            setFilteredCRs(past)
        }
    }, [CRs])

  return (
      <Col style={_style}>
        {filteredCRs.length > 0 ? 
            morefilteredCRs.map((CR, index) => {
            return(
                <ToScheduleCard CR={CR} index={index + indexOffset} refreshList={refreshList} key={'scheduleCardComp' + CR.id} />
            )
            })
            :
            <p style={{fontSize: '1.2rem', color: 'grey', marginLeft: 20}}>- none to Schedule -</p>
        }
        </Col>
    );
}

export default ToScheduleList;