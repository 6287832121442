import React, { useEffect, useState } from 'react'
import { Row, Col, Form, Button, Container, Spinner, Modal } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { activeEndpoint } from '../../common/FauxEnv'
import RadioForm from '../../common/RadioForm'

import csLogo from '../../common/csLogo.png'

import './FadeAnimations.css'
import './ClientAgendaForm.css'

function ClientAgendaForm(props) {
  const [screen, setScreen] = useState(1)
  const [feedback, setFeedback] = useState('')
  const { id } = useParams()

  const [showErrorModal, setShowErrorModal] = React.useState(false)

  const [formattedClientName, setFormattedClientName] = useState('')

  const [meetingData, setMeetingData] = useState(null)

  useEffect(() => {
    getMeetingInfo(id, setMeetingData, setFormattedClientName)
  }, [])

  return (
    <div className='AgendaForm-topdiv' style={{ justifyContent: 'flex-start' }}>
      <Container>
        <Row>
          <Col>
            <a href='https://carystamp.com/' style={{ textDecoration: 'none' }}>
              <img src={csLogo} alt='logo' className='csLogo' />
            </a>
            {/* <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-end'}}> */}
            <div className='bottomLine'></div>
            {/* </div>  */}

            <p className='createFeedback'>{feedback}</p>
          </Col>
        </Row>

        {meetingData != null ? (
          <>
            {' '}
            {!meetingData.dataExists || screen > 1 ? (
              <>
                {screen < 2 && <FirstScreen meetingData={meetingData} formattedClientName={formattedClientName} setScreen={setScreen} />}
                {screen === 2 && <FormScreen meetingData={meetingData} setScreen={setScreen} setShowErrorModal={setShowErrorModal} formattedClientName={formattedClientName} />}
                {screen === 3 && <NoteScreen meetingData={meetingData} setScreen={setScreen} setShowErrorModal={setShowErrorModal} />}
                {screen === 4 && <FinalScreen meetingData={meetingData} setScreen={setScreen} />}
              </>
            ) : (
              <AlreadyDoneScreen meetingData={meetingData} formattedClientName={formattedClientName} setScreen={setScreen} />
            )}
          </>
        ) : (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 20 }}>
            <Spinner animation='border' variant='light' />
          </div>
        )}

        <Modal show={showErrorModal} onHide={() => setShowErrorModal(false)}>
          <Modal.Body style={{ display: 'flex', flexDirection: 'column' }}>
            <Col style={{ padding: 6, textAlign: 'center' }}>
              <h2 className=' ErrorText PTFont'>Something went wrong, please try again</h2>
            </Col>
            <Button style={{ alignSelf: 'center' }} className='SecondaryButton BlueButton PTFont' variant='outline-primary' onClick={() => setShowErrorModal(false)}>
              Close
            </Button>
          </Modal.Body>
        </Modal>
      </Container>
    </div>
  )
}

async function getMeetingInfo(meetingId, setMeetingData, setFormattedClientName) {
  var myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')

  var requestOptions = {
    headers: myHeaders,
    method: 'GET',
    redirect: 'follow',
    mode: 'cors',
  }

  var toReturn
  var fetchCode
  await fetch(activeEndpoint + '/Meeting/Unauth/' + meetingId, requestOptions)
    .then((response) => {
      fetchCode = response.status
      console.log('rsponse', response)
      return response.json()
    })
    .then((result) => {
      if (fetchCode === 200) {
        console.log('result (200): ', result)
        setMeetingData(result.Meeting)

        //   console.log("result.Meeting.clientName", result.Meeting.clientName)
        const ind = result.Meeting.clientName.indexOf('(')
        if (ind == -1) {
          // console.log("-1", result.Meeting.clientName.split(' ')[0])
          //set to first word (first name)
          setFormattedClientName(result.Meeting.clientName.split(' ')[0])
        } else {
          // console.log("ind", result.Meeting.clientName.split('(')[1].split(')')[0])
          //set to string within the parenthesis
          setFormattedClientName(result.Meeting.clientName.split('(')[1].split(')')[0])
        }

        toReturn = true
      } else {
        console.log('error: ', result, '\nwithCode: ', fetchCode)
        toReturn = false
      }
    })
    .catch((error) => {
      console.log('error', error)
      toReturn = false
    })
  return toReturn
}

function AlreadyDoneScreen({ meetingData, formattedClientName, setScreen }) {
  return (
    <div className='in-right' id='AlreadyDoneScreenID'>
      <h2 className='subHeadline PTFont'>Hello, {formattedClientName}</h2>
      <h1 className='Headline PTFont'>You've already filled out the form!</h1>
      <h2 className='subHeadline PTFont'>We look forward to seeing you on {new Date(meetingData.meetingDate).toString().substr(4, 11)}</h2>
      <div style={{ marginTop: '30px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-end' }}>
        <Button
          className='SecondaryButton PTFont'
          variant='outline-light'
          onClick={() => {
            setTimeout(() => {
              setScreen(2)
            }, 600)
            // remove showMe class from current image
            var e = document.getElementById('AlreadyDoneScreenID')
            removeClass(e, 'in-right')

            // add showMe class to next image
            var e = document.getElementById('AlreadyDoneScreenID')
            addClass(e, 'out-left')
          }}
        >
          Redo Form
        </Button>
      </div>
    </div>
  )
}

function FirstScreen({ meetingData, formattedClientName, setScreen }) {
  return (
    <div className='in-right' id='FirstScreenID'>
      <h1 className='Headline PTFont'>Hello, {formattedClientName}</h1>
      <h2 className='subHeadline PTFont'>Let's prepare for your meeting on {new Date(meetingData.meetingDate).toString().substr(4, 11)}</h2>
      <div style={{ marginTop: '30px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-end' }}>
        <Button
          className='PrimaryButton PTFont'
          variant='outline-light'
          onClick={() => {
            setTimeout(() => {
              setScreen(2)
            }, 600)
            // remove showMe class from current image
            var e = document.getElementById('FirstScreenID')
            removeClass(e, 'in-right')

            // add showMe class to next image
            var e = document.getElementById('FirstScreenID')
            addClass(e, 'out-left')
          }}
        >
          Get started
        </Button>
      </div>
    </div>
  )
}

function FinalScreen({ meetingData, setScreen }) {
  return (
    <div className='in-right' id='FirstScreenID'>
      <h1 className='Headline PTFont'>All set!</h1>
      <h2 className='subHeadline PTFont'>We look forward to seeing you on {new Date(meetingData.meetingDate).toString().substr(4, 11)}</h2>
      <div style={{ marginTop: '30px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-end' }}>
        {/* <Button className='PrimaryButton PTFont' variant="outline-light" onClick={() => {
                    setTimeout(() => {
                        setScreen(2)
                    }, 600)
                    // remove showMe class from current image
                        var e = document.getElementById("FirstScreenID");
                        removeClass(e, "in-right");
                        
                        // add showMe class to next image
                        var e = document.getElementById("FirstScreenID");
                        addClass(e, "out-left");
                }}>Get started</Button> */}
      </div>
    </div>
  )
}

function FormScreen({ setScreen, meetingData, setShowErrorModal, formattedClientName }) {
  const [feedback, setFeedback] = useState('')
  const [inputFeedback, setInputFeedback] = useState('')
  const [loading, setLoading] = useState(false)
  const [items, setItems] = useState('')

  const AGENDA_ITEMS = ['Personal / Family Update', 'Family Planning', 'College Funding', 'Portfolio Review', 'Estate Planning', 'Retirement Cash Flow', 'Taxes', 'Beneficiary Review', 'Long Term Care']

  return (
    <Row className='in-right' id='FormScreenID'>
      <Col>
        <Row>
          <h2 className='title PTFont'>What would you like to cover in your meeting?</h2>
          <div className='formDiv'>
            <RadioForm
              options={AGENDA_ITEMS}
              chosen={items}
              setChosen={(val) => {
                setItems(!items.includes(val) ? [...items, val] : [...items.filter((x) => x !== val)])
              }}
              buttonVarient='light'
              buttonStyleOverride={{
                // color: '#a49',
                margin: 5,
              }}
              cols={1}
              showOther={false}
            />
          </div>
        </Row>
        <div style={{ marginTop: '30px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-end' }}>
          <Button
            className='PrimaryButton PTFont'
            variant='outline-light'
            onClick={() => {
              setLoading(true)
              handleSubmitForm(
                meetingData,
                items,
                //this function is soley for the animation
                (success = true) => {
                  setLoading(false)
                  if (success) {
                    setTimeout(() => {
                      setScreen(3)
                    }, 600)
                    var e = document.getElementById('FormScreenID')
                    removeClass(e, 'in-right')

                    // add showMe class to next image
                    var e = document.getElementById('FormScreenID')
                    addClass(e, 'out-left')
                  }
                },
                setShowErrorModal,
                formattedClientName
              )
            }}
          >
            {loading ? <Spinner animation='border' /> : 'Next'}
          </Button>
        </div>
      </Col>
    </Row>
  )
}

function NoteScreen({ setScreen, meetingData, setShowErrorModal }) {
  const [feedback, setFeedback] = useState('')
  const [inputFeedback, setInputFeedback] = useState('')
  const [loading, setLoading] = useState(false)
  const [noteText, setNoteText] = useState('')

  return (
    <Row className='in-right' id='NoteScreenID'>
      <Col>
        <Row>
          <h2 className='title PTFont'>Is there anything else you'd like to mention?</h2>
          <div className='notesContainer'>
            <Form>
              <Form.Control as='textarea' rows={6} size='lg' placeholder='Enter notes here' value={noteText} onChange={(e) => setNoteText(e.target.value)} testid={'notesArea'} className='notesArea' />
            </Form>
          </div>
        </Row>
        <div style={{ marginTop: '30px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-end' }}>
          <Button
            className='PrimaryButton PTFont'
            variant='outline-light'
            onClick={() => {
              setLoading(true)
              handleSubmitNotes(
                meetingData,
                noteText,
                //this function is soley for the animation
                (success = true) => {
                  setLoading(false)
                  if (success) {
                    setTimeout(() => {
                      setScreen(4)
                    }, 600)
                    var e = document.getElementById('NoteScreenID')
                    removeClass(e, 'in-right')

                    // add showMe class to next image
                    var e = document.getElementById('NoteScreenID')
                    addClass(e, 'out-left')
                  }
                },
                setShowErrorModal
              )
            }}
          >
            {loading ? <Spinner animation='border' /> : 'Finish'}
          </Button>
        </div>
      </Col>
    </Row>
  )
}

async function handleSubmitForm(meetingData, newData, doneFunction, setShowErrorModal, formattedClientName) {
  console.log('newData: ', newData)
  var success = await sendMeetingUpdate(meetingData.id, meetingData.clientName, 'clientAgendaItems', [newData])
  if (success) {
    handleSendEmailNotification(formattedClientName)
    doneFunction()
  } else {
    // window.alert('Something went wrong, please try again later.')
    setShowErrorModal(true)
    doneFunction(false)
  }
}

async function handleSubmitNotes(meetingData, newData, doneFunction, setShowErrorModal) {
  var success = await sendMeetingUpdate(meetingData.id, meetingData.clientName, 'clientNote', [newData])
  if (success) {
    doneFunction()
  } else {
    // window.alert('Something went wrong, please try again later.')
    setShowErrorModal(true)
    doneFunction(false)
  }
}

async function sendMeetingUpdate(_id, clientName, keys = [], values = []) {
  // console.debug('sendMeetingUpdate: ', _id, clientName, keys, values)

  var myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')

  var raw = JSON.stringify({
    id: _id,
    clientName: clientName,
    updateKey: keys,
    updateValues: values,
  })

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
    mode: 'cors',
  }

  var toReturn
  var fetchCode
  await fetch(activeEndpoint + '/Meeting/Unauth/' + _id, requestOptions)
    .then((response) => {
      fetchCode = response.status
      if (response.ok) return response.json()
    })
    .then(async (result) => {
      if (fetchCode === 200) {
        console.log('result (200): ', result)
        toReturn = true
      } else {
        console.log('error: ', result, '\nwithCode: ', fetchCode)
        toReturn = false
      }
    })
    .catch((error) => {
      console.log('error', error)
      toReturn = false
    })
  return toReturn
}

async function handleSendEmailNotification(formattedClientName) {
  //this is already done on the backend
  // const recipient = 'tom@forwardtechfl.com'
  // const subject = `${formattedClientName ?? 'Someone'} has filled out their meeting form`
  // var myHeaders = new Headers();
  // myHeaders.append("Content-Type", "application/json");
  // var raw = JSON.stringify({
  //     "recipients": [recipient],
  //     "subject": subject,
  //     "message": `${subject}\n\n
  //             check it out!`
  // });
  // var requestOptions = {
  //   method: 'POST',
  //   headers: myHeaders,
  //   body: raw,
  //   redirect: 'follow'
  // };
  // var fetchCode
  // fetch(activeEndpoint + '/email/unauth', requestOptions)
  //   .then(response => {
  //      fetchCode = response.status
  //      response.text()})
  //   .then(async (result) => {
  //     if(fetchCode === 200) {
  //         console.log('email success')
  //     }
  //     else {
  //         console.log('delete failed with code: ', fetchCode)
  //     }
  //   })
  //   .catch(error => console.log('delete Error', error));
}

function addClass(elem, name) {
  var c = elem.className
  if (c) c += ' ' // if not blank, add a space separator
  c += name
  elem.className = c
}

function removeClass(elem, name) {
  var c = elem.className
  elem.className = c
    .replace(name, '')
    .replace(/\s+/g, ' ')
    .replace(/^\s+|\s+$/g, '') // remove name and extra blanks
}

export default ClientAgendaForm
