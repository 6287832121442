import React, { useEffect, useState } from 'react'
import { Row, Col, Card, Container, Button, Form } from 'react-bootstrap'
import { Auth } from 'aws-amplify'
import ReactLoading from 'react-loading'
import RadioForm from '../../common/RadioForm'

import { secondaryCardColor, secondaryFont } from '../../common/style'

import ToReviewList from './ToReviewList'
import { activeEndpoint, activeLocation, SchedulerEmail } from '../../common/FauxEnv'

import './ReviewScreen.css'

function ReviewScreen() {
  const [loading, setLoading] = useState(true)
  const [CRs, setCRs] = useState([])
  const [filteredCRs, setFilteredCRs] = useState(CRs)

  const [feedback, setFeedback] = useState('') //todo: change this to a toast
  //    const [awsUser, setAwsUser] = useState('');
  const [search, setSearch] = useState('')
  const [leadAdvisorFilter, setLeadAdvisorFilter] = useState('')
  const [URLsearch, setURLSearch] = useState(null)

  const ADVISORS = ['Cary Stamp', 'Jack Schanne', 'Ryan Swenson', 'Matt Mellusi', 'Austin Kuyrkendall', 'Rob Taylor']

  const thirtyDaysOut = new Date().getTime() + 86400 * 1000 * 30

  useEffect(() => {
    console.log('hey')
    getList()
  }, [])

  useEffect(() => {
    if (URLsearch) {
      setSearch(URLsearch)
    }
  }, [URLsearch])

  useEffect(() => {
    console.log('CR.length changed to ', CRs.filter((x) => x.stage == 2).length)
    setFilteredCRs(CRs.filter((x) => x.stage == 2))
  }, [CRs])

  useEffect(() => {
    if (filteredCRs.length > 0) {
      var url_string = window.location.href
      var url = new URL(url_string)
      var localURLsearch = url.searchParams.get('search')
      console.log('URLsearch:', localURLsearch)
      if (localURLsearch) {
        setURLSearch(localURLsearch)
      }
    }
  }, [filteredCRs])

  useEffect(() => {
    var tmpCrs = CRs
    if (!(search === '' || search === undefined || search === null)) {
      tmpCrs = tmpCrs.filter((cr) => cr.clientName.toLowerCase().includes(search.toLowerCase()))
    } else if (!(leadAdvisorFilter === '' || leadAdvisorFilter === undefined || leadAdvisorFilter === null)) {
      tmpCrs = tmpCrs.filter((cr) => cr.leadAdvisor === leadAdvisorFilter)
    }

    if (tmpCrs != filteredCRs) {
      console.log('setfilter')
      setFilteredCRs(tmpCrs)
    }
  }, [search, leadAdvisorFilter])

  //   useEffect(() => {
  //     var tmpCrs = CRs
  //     if (leadAdvisorFilter === '' || leadAdvisorFilter === undefined || leadAdvisorFilter === null) {
  //     } else {
  //     }

  //     if (tmpCrs?.length != filteredCRs.length) {
  //       console.log('setfilter')
  //       setFilteredCRs(tmpCrs)
  //     }
  //   }, [leadAdvisorFilter])

  async function getList() {
    var myHeaders = new Headers()
    //myHeaders.append("Content-Type", "application/json");
    myHeaders.append('Authorization', `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`)

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      mode: 'cors',
      body: JSON.stringify({
        stage: 2,
      }),
    }

    var fetchCode
    fetch(activeEndpoint + '/Meeting/stage', requestOptions)
      .then((response) => {
        fetchCode = response.status
        return response.text()
      })
      .then((result) => {
        if (fetchCode === 200) {
          const parsed = JSON.parse(result)
          console.log('Review fetch: ', parsed)

          //filter to only stage 2
          const filtered = parsed.CRs //.filter(x => x.stage == 2);

          setCRs(filtered)
          setLoading(false)
        } else {
          console.log('error: ', result, '\nwithCode: ', fetchCode)
          setFeedback('ErrorCode: ' + fetchCode)
          setLoading(false)
        }
      })
      .catch((error) => console.log('error', error))
  }

  async function createNewCR(client, dueDate) {
    var myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append('Authorization', `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`)

    var raw = JSON.stringify({
      author: 'awsUser',
      clientName: client,
      dueDate: dueDate,
    })

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    }

    var fetchCode
    fetch(activeEndpoint + '/Meeting', requestOptions)
      .then((response) => {
        fetchCode = response.status
        response.text()
      })
      .then((result) => {
        if (fetchCode === 200) {
          setFeedback('Successfully created new Meeting')
          getList()
          console.log('result: ', result)
        } else {
          console.log('error: ', result, '\nwithCode: ', fetchCode)
          setFeedback('ErrorCode: ' + fetchCode)
        }
      })
      .catch((error) => console.log('error', error))
  }

  function printDataOnClick(data) {
    console.log('data: ', data)
  }

  function handleEmailClick() {
    var CRsToCount = CRs
    //filter to only what Shay need to Schedule
    CRsToCount = CRsToCount.filter((CR) => CR.stage == 3)
    //filter to only those that are due this month
    CRsToCount = CRsToCount.filter((x) => new Date(x.dueDate).toString().substr(4, 3) == new Date().toString().substr(4, 3))

    if (CRsToCount.length == 0) {
      setFeedback('No clients to notifiy Shay about')
    } else {
      var emailBody = 'Go here to Schedule to them: ' + activeLocation + 'schedule' + ' %0a%0aMeetings to Schedule:'

      CRsToCount.forEach((CR) => {
        emailBody += '%0a%0a%09' + CR.clientName.toString() + '%0a%09%09 Due:%09%09%09' + new Date(CR.dueDate).toString().substr(0, 15) + '%0a%09%09 Participants:%09%09' + CR.participants.join(', ') + '%0a%09%09 Medium:%09%09%09' + CR.medium + '%0a%09%09 Urgency:%09%09%09' + CR.urgency
      })

      // setFeedback('Cary Email: ' + CRsToCount.length.toString())
      window.location = 'mailto:' + SchedulerEmail + '?subject=You have ' + CRsToCount.length + ' Meetings to schedule to&body=' + emailBody
    }
  }

  return (
    <div className='App-topdiv' style={{ justifyContent: 'flex-start', fontFamily: secondaryFont }}>
      <Container fluid style={{ alignSelf: 'flex-start' }}>
        <Row>
          <Col xs={3}>
            <div
              className='sticky-top'
              style={{
                overflowY: 'auto',
                overflowX: 'hidden',
                maxHeight: '99vh',
              }}
            >
              <Row style={{ padding: 20, paddingBottom: 0 }}>
                <h2 className='Createh2'>Filter</h2>
                <div className='bottomLine'></div>
              </Row>

              {/* <Card onClick={() => {setSearch("")}} style={{marginBottom: 20, marginLeft:'20%', marginRight: '20%' }}>
                     <p style={{fontSize: 14, margin: 5, alignSelf: 'center'}}>Show all</p>
                  </Card> */}

              <Button
                variant={'outline-secondary'}
                style={{ marginBottom: 20, marginLeft: '20%', marginRight: '20%', alignSelf: 'center', textAlign: 'center', borderRadius: 20, width: 150 }}
                onClick={() => {
                  setSearch('')
                  setLeadAdvisorFilter('')
                }}
              >
                Show all
              </Button>

              <Row style={{ padding: 5 }}>
                <Form>
                  <div>
                    <Form.Group className='mb-3' controlId='formBasicEmail'>
                      <h4>Type name...</h4>
                      <Form.Control
                        type='text'
                        placeholder='[Name]'
                        value={search}
                        testid='search_form'
                        onChange={(e) => {
                          setSearch(e.target.value)
                          setLeadAdvisorFilter('')
                        }}
                      />
                    </Form.Group>
                  </div>
                </Form>
                <h4>Or click advisor...</h4>
                {CRs.length > 0 ? (
                  <RadioForm
                    options={CRs.filter((x) => x.leadAdvisor).map((x) => x.leadAdvisor)}
                    chosen={leadAdvisorFilter}
                    setChosen={(val) => {
                      setLeadAdvisorFilter(val)
                      setSearch('')
                    }}
                    showOther={false}
                    otherValue={'q'}
                  />
                ) : null}
                <h4>Or click name...</h4>
                {CRs.length > 0 ? (
                  <RadioForm
                    options={CRs.map((x) => x.clientName)}
                    chosen={search}
                    setChosen={(val) => {
                      setLeadAdvisorFilter('')
                      setSearch(val)
                    }}
                    showOther={false}
                    otherValue={'q'}
                  />
                ) : null}
                {/* <div>
 
                        {
                           CRs.map((CR, index) => { return(
                              <Card onClick={() => {setSearch(CR.clientName)}} style={{marginBottom: 10}}>
                                 <p style={{fontSize: 14, margin: 5}}>{CR.clientName}</p>
                              </Card>
                           )})
                        }
                     </div> */}
              </Row>
            </div>
          </Col>
          <Col>
            <Row style={{ marginBottom: 0 }}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                <h1 className='Createh1'>Review Meetings</h1>
                {/* <Button variant="outline-primary" onClick={() => {handleEmailClick()}}
                           style={{  height: 40, margin: 10 }}
                        > Send Email Reminder to Shay </Button>         */}
              </div>

              <div className='bottomLine'></div>
              <p className='createFeedback'>{feedback}</p>
              <div style={{ textAlign: 'center' }}> {filteredCRs.length == 0 && search.length > 0 ? <h4>No meetings for "{search}"</h4> : null} </div>
            </Row>

            <Row>
              <Col className='ReviewCardList_Container' style={{ borderRight: '1px solid #e6e6e6' }}>
                <div style={{ textAlign: '-webkit-center' }}>{loading ? <ReactLoading type={'spin'} color={secondaryCardColor} /> : null}</div>

                {filteredCRs.filter((x) => x.dueDate < thirtyDaysOut && x.dueDate > new Date().getTime()).length > 0 ? (
                  <>
                    <Row>
                      <h2 className='Createh2'>This Month: </h2>
                    </Row>
                    <Row id='thisMonthID'>
                      <ToReviewList CRs={filteredCRs} refreshList={getList} type='thismonth' indexOffset={0} _style={{ padding: '1%' }} />
                    </Row>
                  </>
                ) : null}

                <div style={{ marginBottom: 30 }}></div>

                {filteredCRs.filter((x) => x.dueDate < new Date().getTime()).length > 0 ? (
                  <>
                    <Row>
                      <h2 className='Createh2'>Late </h2>
                    </Row>
                    <Row>
                      <ToReviewList CRs={filteredCRs} refreshList={getList} type='past' indexOffset={2000} _style={{ padding: '1%' }} />
                    </Row>
                  </>
                ) : null}

                <div style={{ marginBottom: 30 }}></div>

                {filteredCRs.filter((x) => x.dueDate > thirtyDaysOut).length > 0 ? (
                  <>
                    <Row>
                      <h2 className='Createh2'>In the future: </h2>
                    </Row>
                    <Row id='futureID'>
                      <ToReviewList CRs={filteredCRs} refreshList={getList} type='future' indexOffset={1000} _style={{ padding: '1%' }} />
                    </Row>
                  </>
                ) : null}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default ReviewScreen
