import React, { useEffect, useState } from 'react';
import {Row, Col, Button, Card, Form, Modal} from 'react-bootstrap';
import { Check } from 'react-feather';
import ReactLoading from 'react-loading';

import StageCheckMarks from '../../common/StageCheckMarks'
import { activeEndpoint, activeLocation } from '../../common/FauxEnv'

import ChangeDateForm from '../../common/ChangeDateForm';
import MarkAsScheduledForm from './MarkAsScheduledForm';

import { secondaryCardColor, cornerRadius, textColor, cardColor } from '../../common/style';

import {Auth} from 'aws-amplify'

 function ToScheduleCard({CR, index, refreshList}) {

    const [noteText, setNoteText] = useState(CR.notes ?? '');
    const [feedback, setFeedback] = React.useState('');
    const [formattedParticipants, setFormattedParticipants] = useState('');

    const [showForm, setShowForm] = useState(false);
    const [showScheduledModal, setShowScheduledModal] = useState(false);

    const today = new Date();
    const [datesUntil, setDatesUntil] = useState(false);

    useEffect(() => {
        console.log('meeting: ', CR)
        var tmpParts = CR.participants.join(', ');

        setFormattedParticipants(tmpParts);
        setDatesUntil(Math.ceil((CR.dueDate-today.getTime())/(1000*60*60*24)))

    }, [])



    async function handleDateUpdate(newDate) {
        setShowForm(false)
        setFeedback('changing date to...', new Date(newDate).toString().substr(0,15))

        //add one month to CR.dueDate
        var newDueDate = new Date((newDate) + ' 8:00 est').getTime()


        //update CR.dueDate
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`)

        var raw = JSON.stringify({
            "id": CR.id,
            "updateKeys": ["dueDate"],
            "updateValues": [newDueDate]
        });

        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        };

        var fetchCode
        fetch(activeEndpoint + '/Meeting/'+ CR.id, requestOptions)
          .then(response => {
             fetchCode = response.status
             response.text()})
          .then(async (result) =>  {
             if(fetchCode === 200){
                setFeedback('Successfully updated Meeting')
                console.log('result: ', result)
                refreshList()
             }
             else{
                console.log('error: ', result, '\nwithCode: ', fetchCode)
                setFeedback('ErrorCode: ' + fetchCode)
             }
          })
          .catch(error => console.log('error', error));
    }


    async function handleSendForSchedule(complete, note, stage = 100, meetingDate = null){
        // if(selectedParticipants.length < 1){
        //     setFeedback(`Please select at least one participant`);
        //     return;
        // }
        // else if(selectedUrgency === 'none'){
        //     setFeedback(`Please select an urgency`);
        //     return;
        // }
        // else if(selectedMedium === 'none'){
        //     setFeedback(`Please select a medium`);
        //     return;
        // }
        // else {
        //     setFeedback('Sending...')
        //     updateCR()
        // }

       
        updateCR(complete, note, stage, meetingDate)  
    }

    async function updateCR(complete = false, note = false, stage = 100, meetingDate = null) {
        if(complete){
            //currently at stage 3
            if(stage == 100) {
                setFeedback('Marking As Complete...')
            }
            else if (stage == 2) {
                setFeedback('Sending back for Review...')
            }
        }
        else if(note){
            setFeedback('Saving...')
        }


        if(complete == false && note == false){
           console.log('no data')
           return
        }
  
        var _keys = []
        var _values = []
        if(complete == true){
           _keys.push('stage')
           _values.push(stage)
        }
        if(note == true){
           _keys.push('notes')
           _values.push(noteText)
        }
        if(meetingDate != null){
            var newMeetingDate = new Date((meetingDate) + ' 8:00 est').getTime()
            _keys.push('meetingDate')
            _values.push(newMeetingDate)
        }
  
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`)
  
        var raw = JSON.stringify({
            "id": CR.id,
            "updateKeys": _keys,
            "updateValues": _values
        });
  
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        };
  
        var fetchCode
        fetch(activeEndpoint + '/Meeting/' + CR.id, requestOptions)
          .then(response => {
             fetchCode = response.status
             response.text()})
          .then(result => {
             if(fetchCode === 200){
                setFeedback('Successfully updated Meeting')
                console.log('result: ', result)
                if(complete == true) {
                    refreshList()
                }
             }
             else{
                console.log('error: ', result, '\nwithCode: ', fetchCode)
                setFeedback('ErrorCode: ' + fetchCode)
             }
          })
          .catch(error => console.log('error', error));
  
    }


    function handleDelete(CR, index) {
        console.log('delete: ' + CR.id);
        
        const doubleCheck = window.confirm('Are you sure you want to delete '+ CR.clientName + '?');
        if(doubleCheck) {
            newHandleDeleteCR(CR.id)
        }

    }

    // async function handleDeleteCR(id) {        //TO DO: DEBUG
    //     console.log("deleting:", id)
            
    //     //delete the list
    //     var myHeaders = new Headers();
    //     myHeaders.append("Content-Type", "application/json");
    //     myHeaders.append("Authorization", `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`)


    //     var requestOptions = {
    //         body: JSON.stringify({id: id}),
    //         headers: myHeaders,
    //         method: 'delete',
    //         redirect: 'follow'
    //     };

    //     var fetchCode
    //     fetch(activeEndpoint + '/Meeting/' + id, requestOptions)
    //     .then(response => {
    //         fetchCode = response.status
    //         return response.text()})
    //     .then(result => {
    //         console.log('deleteResults: '+ result)  
    //         if(fetchCode === 200) {
    //             refreshList()
    //         }
    //         else {
    //             console.log('delete failed with code: ', fetchCode)
    //         }
    //     })
    //     .catch(error => console.log('delete Error', error));
        
    // }


    async function newHandleDeleteCR(id) {

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`)

        var raw = JSON.stringify({
            "id": id,
            "updateKeys": ["stage"],
            "updateValues": [1000]
        });

        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        };

        var fetchCode
        fetch(activeEndpoint + '/Meeting/' + id, requestOptions)
          .then(response => {
             fetchCode = response.status
             response.text()})
          .then(async (result) => {
            console.log('deleteResults: '+ result)  
            if(fetchCode === 200) {
                refreshList()
            }
            else {
                console.log('delete failed with code: ', fetchCode)
            }
          })
          .catch(error => console.log('delete Error', error));

    }

    

  return (
      <div key={'scheduleCardDiv' + CR.id} style={{
        marginBottom: 100,
      }}>
                <Card 
                    key={'scheduleCard' + CR.id} 
                    style={{  
                        marginRight: 10, 
                        marginBottom: 50,
                        backgroundColor: secondaryCardColor,
                        borderTopLeftRadius: cornerRadius,
                        borderTopRightRadius: cornerRadius,
                    }}
                    testid={'toScheduleCard' + CR.clientName}

                >
                    <div style={{backgroundColor: cardColor, borderTopLeftRadius: cornerRadius, borderTopRightRadius: cornerRadius,}}>
                        <Row
                            style={{  
                                alignItems:  'center',
                                
                            }}
                        >
                            <Col>
                                <Card.Title>
                                    <h4 style={{color: textColor}} className='scheduleCardh4'>{CR.clientName}</h4>
                                </Card.Title>
                            </Col>
                            <Col 
                            >
                                <Card.Body style={{color: textColor}} >
                                    <h4 className='scheduleCardh5'>due {datesUntil >= 0 ? 'in' : null} <b><strong>{ Math.abs(datesUntil) }</strong></b> days {datesUntil < 0 ? 'ago' : null}</h4>
                                    <h5 className='scheduleCardh5'>{new Date(CR.dueDate).toString().substr(0,15)}</h5>
                                </Card.Body>
                            </Col>
                            <Col 
                                xs={2}
                                md={2}
                                lg={2}
                                xl={2}
                                xxl={2}
                            >
                                
                                <StageCheckMarks stage={CR.stage} textColor={textColor} />

                            </Col>
                        </Row>
                    </div>
                    <Row
                        style={{    }}
                    >
                        <Col>
                            <div className="scheduleCardDetailsContainer">
                                <h4 className="scheduleCardDetailsh4">Participants:</h4>
                                <h5 className="scheduleCardDetailsh5">{formattedParticipants ?? '-not specified-'}</h5>
                                
                                <h4 className="scheduleCardDetailsh4">Medium:</h4>
                                <h5 className="scheduleCardDetailsh5">{CR.medium ?? '-not specified-'}</h5>
                                
                                <h4 className="scheduleCardDetailsh4">Urgency:</h4>
                                <h5 className="scheduleCardDetailsh5">{CR.urgency ?? '-not specified-'}</h5>

                            </div>
                        </Col>
                        <Col>
                            <div className="scheduleCardDetailsContainer notesContainer">
                            <h4>Notes:</h4>
                            <Form>
                                <Form.Control as="textarea" rows={6}
                                    placeholder="Enter notes here"
                                    value={noteText}
                                    onChange={(e) => setNoteText(e.target.value)}
                                    testid={'notesArea'+CR.clientName}
                                />
                            </Form>
                            <Button variant="primary" onClick={() => handleSendForSchedule(false,true)}
                                    style={{ width: '7rem', marginTop: 10, marginBottom: 10, justifySelf: 'center'}}
                                >Save Notes </Button>
                                
                            </div>
                        </Col>

                        
                    </Row>
                    <Row>
                        
                        <Col> 
                            <Row>  
                                <Col>           
                                    <Button variant="success" onClick={() => setShowScheduledModal(true)}
                                        style={{ width: '11rem', margin: 10, justifySelf: 'center'}}
                                    >Mark as Scheduled</Button>

                                    <Modal show={showScheduledModal} onHide={() => setShowScheduledModal(false)} testid='scheduledModal'>
                                        <Modal.Body>
                                            <MarkAsScheduledForm 
                                                clientName={CR.clientName.trim().split(' ')[0]} 
                                                onUpdate={(meetingDate) => {handleSendForSchedule(true,false,4,meetingDate)}}   
                                                confirmButtonText='Mark as Scheduled' 
                                            />
                                        </Modal.Body>
                                        <Modal.Footer>
                                        <Button variant="secondary" onClick={() => setShowScheduledModal(false)}>
                                            Close
                                        </Button>
                                        </Modal.Footer>
                                    </Modal>
                                
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                        <Button variant="outline-secondary" onClick={() => handleSendForSchedule(true,false, 2)}
                                    style={{ width: '12rem', margin: 10, marginBottom: 10, justifySelf: 'center'}}
                                    >Send back for Review </Button> </Col>
                        <Col>
                        <Row>
                                <Col>
                                    {/* <Button variant="primary" onClick={() => handleMoveToNextMonth()}
                                        style={{ width: '11rem', margin: 10, justifySelf: 'center'}}
                                    >Move to next month </Button> */}


                                    <Button variant="outline-primary" onClick={() => setShowForm(true)}
                                        style={{ width: '11rem', margin: 10, justifySelf: 'center'}}
                                    >Change Due Date ... </Button>
                                    <Modal show={showForm} onHide={() => setShowForm(false)} testid='moveDateModal'>
                                        <Modal.Body>
                                            <ChangeDateForm 
                                                clientName={CR.clientName.split(' ')[0]} 
                                                onUpdate={handleDateUpdate}    
                                            />
                                        </Modal.Body>
                                        <Modal.Footer>
                                        <Button variant="secondary" onClick={() => setShowForm(false)}>
                                            Close
                                        </Button>
                                        </Modal.Footer>
                                    </Modal>


                                </Col>
                            </Row>
                         </Col>
                        <Col> </Col>
                        <Col> 
                            <Row>
                                <Col>
                                    <Button variant="outline-danger" onClick={() => handleDelete(CR, index)}
                                        style={{ width: '11rem', margin: 10, justifySelf: 'center'}}
                                    >Delete </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Card>
        {!(feedback == '' || feedback == null) ?
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: -50}}>
            {feedback.indexOf("...") > -1 ?
                <div style={{flexDirection: 'column'}}> 
                    <ReactLoading type={'spin'} color="#a1a19f" height={20} width={20} />
                </div>: <></>}
            <div style={{flexDirection: 'column'}}>
                <p className="reviewCardFeedbackText">{feedback}</p>
            </div>
        </div>
        : <></>}
        </div>
    );
}

export default ToScheduleCard;