import React, { useEffect, useState } from 'react'
import { Row, Col, Card, Container, Button, Form } from 'react-bootstrap'
import { Auth } from 'aws-amplify'
import ReactLoading from 'react-loading'
import RadioForm from '../../common/RadioForm'

import { secondaryCardColor } from '../../common/style'

import ToPrepareList from './ToPrepareList'
import { activeEndpoint, activeLocation, SchedulerEmail } from '../../common/FauxEnv'

import './PrepareScreen.css'

function PrepareScreen() {
  const [loading, setLoading] = useState(true)
  const [CRs, setCRs] = useState([])
  const [filteredCRs, setFilteredCRs] = useState(CRs)
  const [participantFilter, setParticipantFilter] = useState('')
  const [filterTrigger, setFilterTrigger] = useState(false)

  const [allParticipants, setAllParticipants] = useState([])

  const [feedback, setFeedback] = useState('') //todo: change this to a toast
  //    const [awsUser, setAwsUser] = useState('');
  const [search, setSearch] = useState('')
  const [URLsearch, setURLSearch] = useState(null)

  const thirtyDaysOut = new Date().getTime() + 86400 * 1000 * 30

  useEffect(() => {
    console.log('hey')
    getList()
  }, [])

  useEffect(() => {
    if (URLsearch) {
      setSearch(URLsearch)
    }
  }, [URLsearch])

  useEffect(() => {
    console.log('CR.length changed to ', CRs.length)
    setFilteredCRs(CRs)

    //go through each CR and pull the unique participants
    let uniqueParticipants = []
    CRs.forEach((cr) => {
      cr.participants.forEach((p) => {
        if (!uniqueParticipants.includes(p)) {
          uniqueParticipants.push(p)
        }
      })
    })
    setAllParticipants(uniqueParticipants)
  }, [CRs])

  useEffect(() => {
    if (filteredCRs.length > 0) {
      var url_string = window.location.href
      var url = new URL(url_string)
      var localURLsearch = url.searchParams.get('search')
      console.log('URLsearch:', localURLsearch)
      if (localURLsearch) {
        setURLSearch(localURLsearch)
      }
    }
  }, [filteredCRs])

  useEffect(() => {
    if (filterTrigger) {
      //it actually doesn't matter what the value is i just want to trigger the useEffect
    }
    var tmpCrs = CRs
    if (search === '' || search === undefined || search === null) {
    } else {
      tmpCrs = tmpCrs.filter((cr) => cr.clientName.toLowerCase().includes(search.toLowerCase()))
    }

    if (participantFilter === '' || participantFilter === undefined || participantFilter === null) {
    } else {
      console.log('even getting here?', tmpCrs)
      tmpCrs = tmpCrs.filter((cr) => cr.participants.includes(participantFilter))
      console.log('even finishing?', tmpCrs)
    }

    if (tmpCrs != filteredCRs) {
      console.log('setfilter')
      setFilteredCRs(tmpCrs)
    }
  }, [search, participantFilter, filterTrigger])

  async function getList() {
    var myHeaders = new Headers()
    //myHeaders.append("Content-Type", "application/json");
    myHeaders.append('Authorization', `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`)

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      mode: 'cors',
      body: JSON.stringify({
        stage: 4,
      }),
    }

    var fetchCode
    fetch(activeEndpoint + '/Meeting/stage', requestOptions)
      .then((response) => {
        fetchCode = response.status
        return response.text()
      })
      .then((result) => {
        if (fetchCode === 200) {
          const parsed = JSON.parse(result)
          console.log('Prepare fetch: ', parsed)

          //filter to only stage 2
          const _filtered = parsed.CRs //.filter(x => x.stage == 2);

          setCRs(_filtered.sort((a, b) => (a.meetingDate > b.meetingDate ? 1 : -1)))
          setLoading(false)
          setFilterTrigger(!filterTrigger)
        } else {
          console.log('error: ', result, '\nwithCode: ', fetchCode)
          setFeedback('ErrorCode: ' + fetchCode)
          setLoading(false)
        }
      })
      .catch((error) => console.log('error', error))
  }

  async function createNewCR(client, dueDate) {
    var myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append('Authorization', `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`)

    var raw = JSON.stringify({
      author: 'awsUser',
      clientName: client,
      dueDate: dueDate,
    })

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    }

    var fetchCode
    fetch(activeEndpoint + '/Meeting', requestOptions)
      .then((response) => {
        fetchCode = response.status
        response.text()
      })
      .then((result) => {
        if (fetchCode === 200) {
          setFeedback('Successfully created new Meeting')
          getList()
          console.log('result: ', result)
        } else {
          console.log('error: ', result, '\nwithCode: ', fetchCode)
          setFeedback('ErrorCode: ' + fetchCode)
        }
      })
      .catch((error) => console.log('error', error))
  }

  function printDataOnClick(data) {
    console.log('data: ', data)
  }

  function handleEmailClick() {
    var CRsToCount = CRs
    //filter to only what Shay need to Schedule
    CRsToCount = CRsToCount.filter((CR) => CR.stage == 3)
    //filter to only those that are due this month
    CRsToCount = CRsToCount.filter((x) => new Date(x.dueDate).toString().substr(4, 3) == new Date().toString().substr(4, 3))

    if (CRsToCount.length == 0) {
      setFeedback('No clients to notifiy Shay about')
    } else {
      var emailBody = 'Go here to Schedule to them: ' + activeLocation + 'schedule' + ' %0a%0aMeetings to Schedule:'

      CRsToCount.forEach((CR) => {
        emailBody += '%0a%0a%09' + CR.clientName.toString() + '%0a%09%09 Due:%09%09%09' + new Date(CR.dueDate).toString().substr(0, 15) + '%0a%09%09 Participants:%09%09' + CR.participants.join(', ') + '%0a%09%09 Medium:%09%09%09' + CR.medium + '%0a%09%09 Urgency:%09%09%09' + CR.urgency
      })

      // setFeedback('Cary Email: ' + CRsToCount.length.toString())
      window.location = 'mailto:' + SchedulerEmail + '?subject=You have ' + CRsToCount.length + ' Meetings to schedule to&body=' + emailBody
    }
  }

  return (
    <div className='App-topdiv' style={{ justifyContent: 'flex-start' }}>
      <Container fluid style={{ alignSelf: 'flex-start' }}>
        <Row>
          <Col xs={3}>
            <div
              className='sticky-top'
              style={{
                overflowY: 'auto',
                overflowX: 'hidden',
                maxHeight: '99vh',
              }}
            >
              <Row style={{ padding: 20, paddingBottom: 0 }}>
                <h2 className='Createh2'>Filter</h2>
                <div className='bottomLine'></div>
              </Row>

              {/* <Card onClick={() => {setSearch("")}} style={{marginBottom: 20, marginLeft:'20%', marginRight: '20%' }}>
                        <p style={{fontSize: 14, margin: 5, alignSelf: 'center'}}>Show all</p>
                     </Card> */}

              <Button
                variant={'outline-secondary'}
                style={{ marginBottom: 20, marginLeft: '20%', marginRight: '20%', alignSelf: 'center', textAlign: 'center', borderRadius: 20, width: 150 }}
                onClick={() => {
                  setSearch('')
                  setParticipantFilter('')
                }}
              >
                Show all
              </Button>

              <Row>
                <h4>By Participant</h4>
                {allParticipants.length > 0 ? (
                  <RadioForm
                    options={allParticipants}
                    chosen={participantFilter}
                    setChosen={(val) => {
                      setParticipantFilter(val)
                    }}
                    showOther={false}
                    otherValue={'q'}
                    keyPrefix={'participantFilter'}
                  />
                ) : null}
                {/* <div className="CreateFormDiv">
                              <div onChange={(value) => {setParticipantFilter(value.target.value)}} >
                                       <input type="radio" value={''} name={"participantFilterRadio"} className="inputCss" /> None
                                          <br/>
                                 <div class="participantsFlex">
                                       {allParticipants.map((p, index) => {
                                          return (
                                             <div class="partItem">
                                                <input type="radio" value={p} name={"participantFilterRadio"} className="inputCss" 
                                                   testid={`participantFilterButton_${p}`}
                                                /> {p} 
                                             </div> 
                                          )
                                       })}
                                 </div>
                              </div>
                           </div> */}
              </Row>

              <Row style={{ paddingTop: 20 }}>
                {/* <Form >
                              <div >
                                 <Form.Group className="mb-3" controlId="formBasicEmail">
                                 <h4>Type name...</h4>
                                    <Form.Control type="text" placeholder="[Name]" value={search} testid='search_form'
                                          onChange={(e) => setSearch(e.target.value)}
                                    />
                                    </Form.Group>
                              </div>
                        </Form> */}
                <h4>By Client Name...</h4>
                {CRs.length > 0 ? (
                  <RadioForm
                    options={CRs.map((x) => x.clientName)}
                    chosen={search}
                    setChosen={(val) => {
                      setSearch(val)
                    }}
                    showOther={false}
                    otherValue={'q'}
                    keyPrefix={'clientNameFilter'}
                  />
                ) : null}
                {/* <div>
   
                           {
                              CRs.map((CR, index) => { return(
                                 <Card onClick={() => {setSearch(CR.clientName)}} style={{marginBottom: 10}}>
                                    <p style={{fontSize: 14, margin: 5}}>{CR.clientName}</p>
                                 </Card>
                              )})
                           }
                        </div> */}
              </Row>
            </div>
          </Col>
          <Col>
            <Row style={{ marginBottom: 0 }}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                <h1 className='Createh1'>Meeting Preparation</h1>
                {/* <Button variant="outline-primary" onClick={() => {handleEmailClick()}}
                           style={{  height: 40, margin: 10 }}
                        > Send Email Reminder to Shay </Button>         */}
              </div>

              <div className='bottomLine'></div>
              <p className='createFeedback'>{feedback}</p>
              <div style={{ textAlign: 'center' }}>
                {' '}
                {filteredCRs.length == 0 && search.length > 0 ? (
                  <h4>
                    No {participantFilter == null ? null : participantFilter} meetings for "{search}"
                  </h4>
                ) : null}{' '}
              </div>
            </Row>

            <Row>
              <Col className='PrepareCardList_Container' style={{ borderRight: '1px solid #e6e6e6' }}>
                <div style={{ textAlign: '-webkit-center' }}>{loading ? <ReactLoading type={'spin'} color={secondaryCardColor} /> : null}</div>

                {filteredCRs.filter((x) => x.dueDate < thirtyDaysOut && x.dueDate > new Date().getTime()).length > 0 ? (
                  <>
                    <Row>
                      <h2 className='Createh2'>This Month: </h2>
                    </Row>
                    <Row id='thisMonthID'>
                      <ToPrepareList CRs={filteredCRs} refreshList={getList} type='thismonth' indexOffset={0} _style={{ padding: '1%' }} />
                    </Row>
                  </>
                ) : null}

                <div style={{ marginBottom: 30 }}></div>

                {filteredCRs.filter((x) => x.dueDate < new Date().getTime()).length > 0 ? (
                  <>
                    <Row>
                      <h2 className='Createh2'>Late </h2>
                    </Row>
                    <Row>
                      <ToPrepareList CRs={filteredCRs} refreshList={getList} type='past' indexOffset={2000} _style={{ padding: '1%' }} />
                    </Row>
                  </>
                ) : null}

                <div style={{ marginBottom: 30 }}></div>

                {filteredCRs.filter((x) => x.dueDate > thirtyDaysOut).length > 0 ? (
                  <>
                    <Row>
                      <h2 className='Createh2'>In the future: </h2>
                    </Row>
                    <Row id='futureID'>
                      <ToPrepareList CRs={filteredCRs} refreshList={getList} type='future' indexOffset={1000} _style={{ padding: '1%' }} />
                    </Row>
                  </>
                ) : null}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default PrepareScreen
