import React, { useEffect, useState } from 'react';
import {Row, Col, Button, Form, Dropdown, DropdownButton, Container} from 'react-bootstrap';

import './CreateScreen.css'
import FollowUp from './FollowUp';


 function AddNewCRForm(props) {



    const [data, setData] = useState({
        name: '',
        dueDate: new Date()
    });

    // useEffect(() => {

    // }, [])

    return (
        <Col className='AddNewCRForm_Container'>
            <Row>
                <h2 className="Createh2">Add Manually</h2>
            </Row>
            <Row>
            <Form className="CreateFormClass">
                <div className="CreateFormDiv">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Contact Name ({'&'} Spouse Name) </Form.Label>
                    <Form.Control type="text" placeholder="John Doe" value={data.name} testid='name_form'
                        onChange={(e) => setData({...data, name: e.target.value})}
                    />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Due Date</Form.Label>
                    <Form.Control type="date" placeholder="Date"  testid='date_form'
                        value={data.dueDate}
                        onChange={(e) => setData({...data, dueDate: e.target.value})}
                    />
                </Form.Group>

                {/* <Form.Group className="mb-3" controlId="formBasicCheckbox">
                    <Form.Check type="checkbox" label="Check me out" />
                </Form.Group> */}

                <Button variant="success" onClick={() => {   props.onClick(data);  }}>
                    Submit
                </Button>

                <p className="inputFeedback">{props.feedback}</p>
                </div>
            </Form>
            </Row>
            {/* <Button variant="success" onClick={() => {   props.onClick(data);  }}
                >Sumbit</Button> */}
        </Col>
    );
}

export default AddNewCRForm;