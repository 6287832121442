import React from 'react'
// import ReactPDF, { Document, Page, Text, View, StyleSheet, PDFViewer, Image, PDFDownloadLink } from '@react-pdf/renderer';
import ReactPDF, { Document, Page, Text, View, StyleSheet, PDFViewer, Image, PDFDownloadLink } from '@react-pdf/renderer'
import { Button } from 'react-bootstrap'
import csLogoInverted from '../../common/csLogoInverted.png'

export default function AgendaPDFBuilder(props) {
  // return (
  //     <PDFViewer width={400} height={600}>
  //         <MyDocument/>
  //     </PDFViewer>
  // )

  return (
    <PDFDownloadLink document={<MyDocument meetingData={props.meetingData} />} fileName={('Agenda ' + props.meetingData.clientName + '_' + new Date(props.meetingData.meetingDate).toString().substr(4, 11) + '.pdf').replaceAll(' ', '_')}>
      {({ blob, url, loading, error }) => (loading ? 'Loading document...' : <Button variant='outline-warning'>Download Agenda PDF</Button>)}
    </PDFDownloadLink>
  )
}

const styles = StyleSheet.create({
  page: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
    fontFamily: 'Helvetica',
  },
  author: {
    fontSize: 12,
    textAlign: 'center',
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
  },
  agendaItem: {
    fontSize: 15,
    margin: 5,
    marginLeft: 20,
    marginTop: 20,
  },
  subAgendaItem: {
    fontSize: 14,
    margin: 10,
    marginLeft: 60,
  },
  clientName: {
    fontSize: 20,
    margin: 12,
    fontWeight: 'black',
    textDecoration: 'underline',
    textAlign: 'center',
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: 'justify',
    fontFamily: 'Times-Roman',
  },
  logoImage: {
    marginVertical: 15,
    marginHorizontal: 100,
    marginBottom: 30,
    opacity: 0.3,
  },
  header: {
    fontSize: 12,
    marginBottom: 0,
    textAlign: 'center',
    color: 'grey',
    textTransform: 'uppercase',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  participants: {
    fontSize: 13,
    textAlign: 'center',
    margin: 10,
    marginLeft: 40,
    marginRight: 40,
    color: 'black',
  },
  clientNotes: {
    fontSize: 13,
    // fontStyle: 'italic',
    position: 'absolute',
    bottom: 90,
    left: 0,
    right: 0,
    textAlign: 'left',
    marginLeft: 40,
    marginRight: 40,
    color: 'black',
  },
  clientNotesTitle: {
    fontSize: 12,
    // fontStyle: 'italic',
    fontWeight: 'bold',
    position: 'absolute',
    bottom: 130,
    left: 0,
    right: 0,
    textAlign: 'center',
    marginLeft: 20,
    marginRight: 20,
    color: 'gray',
    textTransform: 'uppercase',
  },
  footer: {
    fontSize: 8,
    fontStyle: 'italic',
    position: 'absolute',
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    marginLeft: 30,
    marginRight: 30,
    color: 'grey',
  },
})

// Create Document Component
const MyDocument = ({ meetingData }) => (
  <Document>
    <Page style={styles.page}>
      <Image fixed style={styles.logoImage} src={csLogoInverted} />
      <Text style={styles.title}></Text>
      <Text style={styles.clientName}>
        {meetingData.clientName} - {new Date(meetingData.meetingDate).toString().substr(4, 11)}
      </Text>
      {/* <Text style={styles.author}>Miguel de Cervantes</Text> */}

      <Text style={styles.header}>Participants</Text>
      <Text style={styles.participants}>{meetingData.participants.join(', ')}</Text>

      <Text style={styles.header}>Agenda</Text>
      {meetingData.agendaItems
        .filter((item) => item.substring(item.length - 8) != 'prepared')
        .map((item, index) => (
          <Text style={styles.agendaItem}>
            {index + 1}. {item}
          </Text>
        ))}

      <Text fixed style={styles.clientNotesTitle}>
        {' '}
        Notes from the Client{' '}
      </Text>
      <Text fixed style={styles.clientNotes}>
        {meetingData.clientNote}
      </Text>

      <Text fixed style={styles.footer}>
        Securities and advisory services offered through Commonwealth Financial Network, Member FINRA/SIPC, a registered investment advisor. Fixed insurance products and services offered through Cary Stamp & Company and CES Insurance Agency.
      </Text>
    </Page>
  </Document>
)

// export default reactDom.render(<AgendaPDFBuilder/>, document.getElementById('root'));
