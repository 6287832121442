import React, { useEffect, useState } from 'react';
import {Row, Col, Navbar, Nav, Container, Button, Image} from 'react-bootstrap';
import { useHistory, useParams} from "react-router-dom";
import { Auth }  from 'aws-amplify';
import { cardColor, textColor } from '../common/style';
import logo from './web_tools.png';

 function CaryNavBar(props) {
    let history = useHistory();
    // let { slug } = useParams();
    let location = history.location.pathname;
    const [awsUser, setAwsUser] = useState({attributes: {email: null}});


    const SIMPLE_BLACKLIST = {
        Create: [""],
        Review: ["liz@carystamp.com"],
        Schedule: [""],
        Completed: [""],
        Prepare: [""],
    }

    useEffect(() => {
        if(props.awsUser != null) {
            setAwsUser(props.awsUser)
        }
    }, [])

    
    

    return (
        <Navbar variant="light" testid="navbar" style={{backgroundColor: cardColor}}>
            <Container>
            <Navbar.Brand href="/create">
                <img 
                src={logo} 
                alt='logo' 
                width={150}/>
            </Navbar.Brand>
                <Nav className="me-auto"
                    activeKey={location}
                    onSelect={(selectedKey) => history.push(selectedKey)}
                >
                    {/* <p>{location}</p> */}
                    {SIMPLE_BLACKLIST.Create.includes(awsUser.attributes.email) ? <></> : <Nav.Link style={{color:textColor, textDecoration: location == "/create" ? 'underline' : null }} href="/create">Create</Nav.Link>} 
                        <p style={{paddingTop: '0.5rem', color: 'rgba(255,255,255,0.35)'}}>{"→"}</p>
                    {SIMPLE_BLACKLIST.Review.includes(awsUser.attributes.email) ? <></> : <Nav.Link style={{color:textColor, textDecoration: location == "/review" ? 'underline' : null }}  href="/review">Advisor Review</Nav.Link>}
                        <p style={{paddingTop: '0.5rem', color: 'rgba(255,255,255,0.35)'}}>{"→"}</p>
                    {SIMPLE_BLACKLIST.Schedule.includes(awsUser.attributes.email) ? <></> : <Nav.Link style={{color:textColor, textDecoration: location == "/schedule" ? 'underline' : null }}  href="/schedule">Schedule</Nav.Link>}
                        <p style={{paddingTop: '0.5rem', color: 'rgba(255,255,255,0.35)'}}>{"→"}</p>
                    {SIMPLE_BLACKLIST.Prepare.includes(awsUser.attributes.email) ? <></> : <Nav.Link style={{color:textColor, textDecoration: location == "/prepare" ? 'underline' : null }}  href="/prepare">Prepare</Nav.Link>}
                        <p style={{paddingTop: '0.5rem', color: 'rgba(255,255,255,0.35)'}}>{"→"}</p>
                    {SIMPLE_BLACKLIST.Completed.includes(awsUser.attributes.email) ? <></> : <Nav.Link style={{color:textColor, textDecoration: location == "/completed" ? 'underline' : null }}  href="/completed">Completed</Nav.Link>} 
 
                </Nav>
                <Button variant="outline-light" className="ml-auto" testid="logoutButton"
                    onClick={() => {Auth.signOut(); props.onLogout(true)}}
                >Logout</Button>
            </Container>
        </Navbar>
    );
}

export default CaryNavBar;