module.exports = {
  localHostingLocation: 'http://localhost:3000/',
  devHostingLocation: 'https://dev.d1v6zxgdtnzcm4.amplifyapp.com/',
  prodHostingLocation: 'https://prod.d1v6zxgdtnzcm4.amplifyapp.com/',
  DNSHostedLocation: 'https://www.askcarystamp.com/',
  /////////
  activeLocation: 'https://www.askcarystamp.com/',
  /////////

  devAPIEndpoint: 'https://v294oofr4g.execute-api.us-east-1.amazonaws.com/dev',
  prodAPIEndpoint: 'https://7nhbwmz2t1.execute-api.us-east-1.amazonaws.com/prod',

  //////////
  activeEndpoint: 'https://7nhbwmz2t1.execute-api.us-east-1.amazonaws.com/prod',
  //////////

  ReviewerEmail: 'tom@forwardtechfl.com',
  SchedulerEmail: 'tom@forwardtechfl.com',
}
