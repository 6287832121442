import React, { useState } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'

import './ChangeDateCSS.css'
// import FollowUp from './FollowUp';

function ChangeDateForm(props) {
  const [feedback, setFeedback] = useState('')

  const [data, setData] = useState(new Date())

  function onSaveClick() {
    if (data === '') {
      setFeedback('Please enter a due date')
      return
    }

    props.onSaveClick(data)
  }

  return (
    <Col className='ChangeDateForm_Container'>
      <Row>
        <h2 className='Changeh2'>Move {props.clientName} due date to...</h2>
      </Row>
      <Row>
        <Form className='CreateFormClass'>
          <div className='CreateFormDiv'>
            <Form.Group className='mb-3' controlId='formBasicPassword'>
              <Form.Label>New Due Date</Form.Label>
              <Form.Control
                type='date'
                placeholder='Date'
                testid='move_date_form'
                value={data}
                onChange={(e) => {
                  setData(e.target.value)
                }}
              />
            </Form.Group>

            <div className='savebuttonclass'>
              <Button
                variant='primary'
                onClick={() => {
                  props.onUpdate(data)
                }}
                data-testid='move_date_save'
              >
                Save
              </Button>
            </div>

            <p className='inputFeedback'>{feedback}</p>
          </div>
        </Form>
      </Row>
      {/* <Button variant="success" onClick={() => {   props.onClick(data);  }}
                >Sumbit</Button> */}
    </Col>
  )
}

export default ChangeDateForm
