//old style: 
//textColor: null
//cardColor: #f0e5cf
//secondaryColor: #f5f7ff
//cornerRadius: null


export const textColor = 'white'
export const cardColor = '#0c557b' 
export const secondaryCardColor = '#edf0fc'
export const cornerRadius = 10
export const secondaryFont = `   "Montserrat",sans-serif   `
export const overallFont = secondaryCardColor //`   'PT Serif',serif   `
// export const fontScale = 1.2