import React, {useEffect} from 'react';
import {Button, Card, Col, Row, Form, ToggleButtonGroup} from 'react-bootstrap';

import ToReviewCard from './ToReviewCard';

 function ToReviewList({CRs, _style, refreshList, type, indexOffset=0}) {

    const [filteredCRs, setFilteredCRs] = React.useState([]);

    useEffect(() => {
        console.log('type:', type, 'cr', CRs);
        var thirtyDaysOut = (new Date().getTime() + (86400 * 1000 * 30));

        if(type == 'thismonth') {
            let month = CRs.filter(
                x => x.dueDate < thirtyDaysOut && x.dueDate > new Date().getTime()
            )           
            //sort by oldest first
            month.sort((a,b) => {
                return new Date(a.dueDate) - new Date(b.dueDate)
            })

            setFilteredCRs(month)
        }
        else if(type == 'future') {
            let notMonth = CRs.filter(
                x => x.dueDate > thirtyDaysOut && x.dueDate > new Date().getTime()
            )
            
            // console.log(notMonth, ' :notmonth')
            //sort by oldest first
            notMonth.sort((a,b) => {
                return new Date(a.dueDate) - new Date(b.dueDate)
            })

            setFilteredCRs(notMonth)
        }
        else if(type == 'past') {
            let past = CRs.filter(
                x =>  x.dueDate < new Date().getTime()
            )
            
            //sort by oldest first
            past.sort((a,b) => {
                return new Date(a.dueDate) - new Date(b.dueDate)
            })

            setFilteredCRs(past)
        }
    }, [CRs])


  return (
      <Col style={_style}>
        {filteredCRs.length > 0 ? 
            filteredCRs.map((CR, index) => { return(
                <ToReviewCard CR={CR} index={index + indexOffset} refreshList={refreshList} key={'reviewCardComp' + CR.id} />
            )
            })
            :
            <p style={{fontSize: '1.2rem', color: 'grey', marginLeft: 20}}>- none to review -</p>
        }
        </Col>
    );
}

export default ToReviewList;